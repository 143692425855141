import Vue from 'vue';
import store from '@/store/index';
import { Route } from 'vue-router';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { getShippingApplicationData } from '@/api/top-level-apis/shipping/shipping';
import ErrorModel from '@/api/models/ErrorModel';
import { ShippingAppListingStatusProperty } from '@/api/models/ShippingApplicationModel';

const { useActions } = createNamespacedHelpers(store, 'shipping'); //module name

const COMPANY_INFO = 1;
const GENERAL_SETTINGS = 2;
const ABOUT_COMPANY = 3;
const SHIPPING_OPTION_DETAILS = 4;
const WEBHOOK_MANAGEMENT = 5;
const REQUEST_TO_PUBLISH = 6;

export const useRedirectToCreateShippingAppStep = async (to: Route, from: Route, next: any) => {
  const { setInProgressFullApplication, setLoadingAppData, addStepCompleted } = useActions([
    'setInProgressFullApplication',
    'setLoadingAppData',
    'addStepCompleted',
  ]);

  if (from.name === null && to.matched.some((route) => route.meta.createShippingApplicationStep)) {
    store.dispatch('shipping/resetCreateAppProcess');

    if (to.name === RoutingRouteEnum.CreateShippingApplication) {
      next();
      return;
    } else next({ name: RoutingRouteEnum.CreateShippingApplication });
  } else if (
    (from.name === RoutingRouteEnum.CreateShippingApplication &&
      to.name === RoutingRouteEnum.CreateShippingApplication_GeneralSettings) ||
    to.name === RoutingRouteEnum.CreateShippingApplication_StepsContainer
  ) {
    const applicationId = to.query.appId;
    if (typeof applicationId === 'string' || typeof applicationId === 'number') {
      setLoadingAppData(true);
      const response = await getShippingApplicationData(parseInt(applicationId));

      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Could not find application!');
        setLoadingAppData(false);
        next({ name: from.name });
        return;
      }

      setInProgressFullApplication(response);
      const { payload } = response;
      const { step } = payload.app;

      // Redirect if the app is published or in review
      if (
        payload.app.listing_status === ShippingAppListingStatusProperty.Published ||
        payload.app.listing_status === ShippingAppListingStatusProperty.RequestToPublish
      ) {
        Vue.$toast.error('You have no permission to view the application');
        setLoadingAppData(false);
        next({ name: RoutingRouteEnum.Applications });
        return;
      }

      setLoadingAppData(false);
      switch (step) {
        case COMPANY_INFO:
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_CompanyInfo);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_GeneralSettings);
          next({
            name: RoutingRouteEnum.CreateShippingApplication_GeneralSettings,
          });
          return;
        case GENERAL_SETTINGS:
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_CompanyInfo);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_GeneralSettings);
          next({
            name: RoutingRouteEnum.CreateShippingApplication_GeneralSettings,
          });
          return;
        case ABOUT_COMPANY:
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_CompanyInfo);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_GeneralSettings);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_AboutCompany);
          next({ name: RoutingRouteEnum.CreateShippingApplication_AboutCompany });
          return;
        case SHIPPING_OPTION_DETAILS:
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_CompanyInfo);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_GeneralSettings);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_AboutCompany);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails);
          next({
            name: RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails,
          });
          return;
        case WEBHOOK_MANAGEMENT:
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_CompanyInfo);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_GeneralSettings);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_AboutCompany);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_WebhookManagement);
          next({
            name: RoutingRouteEnum.CreateShippingApplication_WebhookManagement,
          });
          return;
        case REQUEST_TO_PUBLISH:
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_CompanyInfo);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_GeneralSettings);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_AboutCompany);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_ShippingOptionDetails);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_WebhookManagement);
          addStepCompleted(RoutingRouteEnum.CreateShippingApplication_Publish);
          next({ name: RoutingRouteEnum.CreateShippingApplication_Publish });
          return;
        default:
          break;
      }
    }
  } else if (
    from.name !== RoutingRouteEnum.Home &&
    from.name !== RoutingRouteEnum.Applications &&
    from.name !== RoutingRouteEnum.CreateShippingApplication_StepsContainer &&
    !from.matched.some((route) => route.meta.createShippingApplicationStep) &&
    to.matched.some((route) => route.meta.createShippingApplicationStep)
  ) {
    next({ name: RoutingRouteEnum.CreateShippingApplication });
  } else next();
};
