<template>
  <div class="relative">
    <div class="cursor-pointer flex items-center justify-between space-x-2" @click="toggleShowUserDropdown">
      <div class="p-2 rounded-full border border-white-pureWhite bg-primary-50">
        <base-icon viewBox="0 0 18 17" class="text-primary-600" iconName="my-profile">
          <profile-icon />
        </base-icon>
      </div>
      <base-text textStyle="h4" class="text-neutrals-600">{{ name }}</base-text>
      <template>
        <zid-icon icon="arrow_down" color="#81808C" />
      </template>
    </div>
    <div class="absolute right-0">
      <zid-click-outside @clickOutside="closeUserDropdown">
        <user-dropdown v-if="showDropdown" />
      </zid-click-outside>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import BaseText from '../text/BaseText.vue';
import BaseIcon from '../base-icon/BaseIcon.vue';
import ProfileIcon from '../../assets/icons/sidebar-icons/ProfileIcon.vue';
import { ZidIcon, ZidClickOutside } from '@zidsa/ui';
import UserDropdown from './UserDropdown.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidIcon,
    ProfileIcon,
    UserDropdown,
    ZidClickOutside,
  },
  setup() {
    const showDropdown = ref(false);
    const { name } = useGetters(['name']);
    const toggleShowUserDropdown = () => (showDropdown.value = !showDropdown.value);
    const closeUserDropdown = () => (showDropdown.value = false);
    return {
      name,
      showDropdown,
      toggleShowUserDropdown,
      closeUserDropdown,
    };
  },
});
</script>
