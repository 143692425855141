import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { serializeParams } from './paramsSerializer';
import useTokenStatus from './useTokenStatus';
import ErrorResponseCodes from './errorResponseCodes';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import store from '../store';
import ErrorModel from './models/ErrorModel';
import getSelectedLocaleFromLocalStorage from '@/helpers/i18n/localeStorage';

const { useActions } = createNamespacedHelpers(store, 'authentication');

const HttpClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_PARTNER_DASHBOARD_ENVIRONMENT_URL,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': process.env.VUE_APP_PARTNER_DASHBOARD_LOCAL_ENVIRONMENT_URL,
    'Content-Type': 'application/json',
    'Accept-Language': getSelectedLocaleFromLocalStorage(),
  },
  responseType: 'json',
  paramsSerializer: serializeParams,
});

HttpClient.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const { partnerToken, partnerTokenValid } = useTokenStatus();
    if (partnerTokenValid && !!partnerToken) {
      config.headers = {
        ...config.headers,
        'x-partner-token': partnerToken,
        'Accept-Language': getSelectedLocaleFromLocalStorage(),
      };
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(new ErrorModel(error.response?.data)),
);

HttpClient.interceptors.response.use(
  (res) => Promise.resolve(res.data),
  (error: AxiosError) => {
    // partner token is invalid or has expired
    if (error.response?.status === 401 && error.response.data.message === ErrorResponseCodes.ERROR_SESSION_INVALID) {
      const { resetPartnerData } = useActions(['resetPartnerData']);
      resetPartnerData();
    }
    return Promise.reject(new ErrorModel(error.response?.data));
  },
);

export default HttpClient;
