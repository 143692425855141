import store from '../../../store/index';
import { Route } from 'vue-router';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useActions } = createNamespacedHelpers(store, 'application');

export const useResetCreateAppProcess = (to: Route, from: Route, next: any) => {
  const { setAppID, setIsEditMode, setTypeForApplicationBeingCreated, resetStepsCompleted } = useActions([
    'setAppID',
    'setIsEditMode',
    'setTypeForApplicationBeingCreated',
    'resetStepsCompleted',
  ]);

  if (
    from.matched.some((route) => route.meta.createApplicationStep) &&
    !to.matched.some((route) => route.meta.createApplicationStep)
  ) {
    setAppID(-1);
    setIsEditMode(false);
    setTypeForApplicationBeingCreated('');
    resetStepsCompleted();
    next();
  } else next();
};
