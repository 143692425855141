import { MutationTree } from 'vuex';
import RootStateType from './RootStateType.type';

const mutations: MutationTree<RootStateType> = {
  IS_APP_LOADING(state, payload) {
    state.isLoading = payload;
  },
  PARTNER_ID(state, payload) {
    state.partnerID = payload;
  },
};

export default mutations;
