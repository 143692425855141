import { createNamespacedHelpers } from 'vuex-composition-helpers';
import store from '@/store';
import useTokenStatus from './useTokenStatus';
import AuthReponseModel from '../domains/authentication/models/AuthReponseModel';
import { setLocalStorge, getLocalStorge, removeLocalStorge } from '@/helpers/localStorage';
import { getSessionStorge, setSessionStorge, removeSessionStorge } from '@/helpers/sessionStorage';
import { AuthenticationResponse } from '../domains/authentication/types/authTypes';
import { isTokenDateValid } from './useTokenStatus';
import { Userpilot } from 'userpilot';
import dayjs from 'dayjs';
import { RootPermissionsEnum } from '@/router/permissions/root-permissions.enum';

const { useActions, useGetters } = createNamespacedHelpers(store, 'authentication');

const {
  setPartnerName,
  setPartnerEmail,
  setPartnerRole,
  setXPartnerTokenState,
  setTokenExpirationDate,
  setPartnerAppsTypes,
  setPartnerAuthenticated,
  setIsAccountOwner,
  resetPartnerData,
  setAccountCreationStepsState,
  setAnAppHasBeenCreated,
  setPartnerIntercomHash,
} = useActions([
  'setPartnerName',
  'setPartnerEmail',
  'setPartnerRole',
  'setPartnerAuthenticated',
  'setIsAccountOwner',
  'setXPartnerTokenState',
  'setTokenExpirationDate',
  'setPartnerAppsTypes',
  'resetPartnerData',
  'setAccountCreationStepsState',
  'setAnAppHasBeenCreated',
  'setPartnerIntercomHash',
]);

const useAuthStatus = () => {
  const { isPartnerAuthenticated } = useGetters(['isPartnerAuthenticated']);
  const { partnerToken, partnerTokenValid } = useTokenStatus();

  const partnerAuthenticated = isPartnerAuthenticated.value && !!partnerToken && partnerTokenValid;

  return {
    partnerAuthenticated,
  };
};

export const saveAuthStateToLocalStorageAndGlobalState = (
  response: AuthReponseModel,
  areAccountCreationStepsCompleted = false,
  hasAnAppBeenCreated = false,
  rememberPartner = false,
) => {
  // userpilot identification
  Userpilot.identify(`${response.partner.id}`, {
    name: response.partner.name,
    email: response.partner.email,
    created_at: dayjs().toISOString(),
    email_verified: areAccountCreationStepsCompleted,
    application_type: response.partner.application_type,
    looking_for_ideas: response.partner.looking_for_ideas,
    country: response.partner.country,
  });

  if (rememberPartner) {
    // local storage
    setLocalStorge(AuthenticationResponse.PartnerID, response.partner.id);
    setLocalStorge(AuthenticationResponse.Name, response.partner.name);
    setLocalStorge(AuthenticationResponse.Email, response.partner.email);
    setLocalStorge(AuthenticationResponse.Role, response.partner.role);
    setLocalStorge(AuthenticationResponse.PartnerToken, response.partner['x-partner-token']);
    setLocalStorge(AuthenticationResponse.Token_expiry_date, response.partner.token_expiry_date);
    setLocalStorge(AuthenticationResponse.AppsTypes, JSON.stringify(response.partner.apps_types));
    setLocalStorge(AuthenticationResponse.IsAccountOwner, response.partner.is_account_owner);
    setLocalStorge('areAccountCreationStepsCompleted', areAccountCreationStepsCompleted);
    setLocalStorge('hasAnAppBeenCreated', hasAnAppBeenCreated);
    setLocalStorge(AuthenticationResponse.IntercomHash, response.partner.intercom_user_hash);
  } else {
    // session storage
    setSessionStorge(AuthenticationResponse.PartnerID, response.partner.id);
    setSessionStorge(AuthenticationResponse.Name, response.partner.name);
    setSessionStorge(AuthenticationResponse.Email, response.partner.email);
    setSessionStorge(AuthenticationResponse.Role, response.partner.role);
    setSessionStorge(AuthenticationResponse.PartnerToken, response.partner['x-partner-token']);
    setSessionStorge(AuthenticationResponse.Token_expiry_date, response.partner.token_expiry_date);
    setSessionStorge(AuthenticationResponse.AppsTypes, JSON.stringify(response.partner.apps_types));
    setSessionStorge(AuthenticationResponse.IsAccountOwner, response.partner.is_account_owner);
    setSessionStorge('areAccountCreationStepsCompleted', areAccountCreationStepsCompleted);
    setSessionStorge('hasAnAppBeenCreated', hasAnAppBeenCreated);
    setLocalStorge('sessionStorage', JSON.stringify(sessionStorage));
    setSessionStorge(AuthenticationResponse.IntercomHash, response.partner.intercom_user_hash);
  }
  // global store
  store.dispatch('setPartnerID', response.partner.id);
  setPartnerName(response.partner.name);
  setPartnerEmail(response.partner.email);
  setPartnerRole(response.partner.role);
  setXPartnerTokenState(response.partner['x-partner-token']);
  setTokenExpirationDate(response.partner.token_expiry_date);
  setPartnerAppsTypes(response.partner.apps_types);
  setPartnerAuthenticated(true);
  setIsAccountOwner(response.partner.is_account_owner);
  setAccountCreationStepsState(areAccountCreationStepsCompleted);
  setAnAppHasBeenCreated(hasAnAppBeenCreated);
  setPartnerIntercomHash(response.partner.intercom_user_hash);
};

export const saveGrantedPermissionsToLocalStorageAndGlobalState = (
  grantedPermissions: RootPermissionsEnum[] = [],
  rememberPartner = false,
): Promise<void> => {
  if (rememberPartner) {
    // local storage
    setLocalStorge('grantedPermissions', JSON.stringify(grantedPermissions));
  } else {
    // session storage
    setSessionStorge('grantedPermissions', JSON.stringify(grantedPermissions));
    setLocalStorge('permissionsSessionStorage', JSON.stringify(sessionStorage));
  }
  // global store
  return store.dispatch('teamMember/setTeamMemberGrantedPermissions', grantedPermissions);
};

export const resetStorageAndGlobalStateAuth = () => {
  // local storage
  removeLocalStorge(AuthenticationResponse.PartnerID);
  removeLocalStorge(AuthenticationResponse.Name);
  removeLocalStorge(AuthenticationResponse.Email);
  removeLocalStorge(AuthenticationResponse.Role);
  removeLocalStorge(AuthenticationResponse.PartnerToken);
  removeLocalStorge(AuthenticationResponse.Token_expiry_date);
  removeLocalStorge(AuthenticationResponse.AppsTypes);
  removeLocalStorge(AuthenticationResponse.IsAccountOwner);
  removeLocalStorge('areAccountCreationStepsCompleted');
  removeLocalStorge('hasAnAppBeenCreated');
  removeLocalStorge(AuthenticationResponse.IntercomHash);
  //session storage
  removeSessionStorge(AuthenticationResponse.PartnerID);
  removeSessionStorge(AuthenticationResponse.Name);
  removeSessionStorge(AuthenticationResponse.Email);
  removeSessionStorge(AuthenticationResponse.Role);
  removeSessionStorge(AuthenticationResponse.PartnerToken);
  removeSessionStorge(AuthenticationResponse.Token_expiry_date);
  removeSessionStorge(AuthenticationResponse.AppsTypes);
  removeSessionStorge(AuthenticationResponse.IsAccountOwner);
  removeSessionStorge('areAccountCreationStepsCompleted');
  removeSessionStorge('hasAnAppBeenCreated');
  removeLocalStorge('sessionStorage');
  removeSessionStorge(AuthenticationResponse.IntercomHash);
  // global store
  resetPartnerData();
  store.dispatch('setPartnerID', -1);
};

export const resetStorageAndGlobalStatePermissions = () => {
  // local storage
  removeLocalStorge('grantedPermissions');
  //session storage
  removeSessionStorge('grantedPermissions');
  removeLocalStorge('permissionsSessionStorage');
  // global store
  store.dispatch('teamMember/setTeamMemberGrantedPermissions', []);
};

const getValuesFromStorage = () => {
  let partnerID = null;
  let name = null;
  let email = null;
  let role = null;
  let partnerToken = null;
  let token_expiry_date = null;
  let apps_types = null;
  let isAccountOwner = null;
  let areAccountStepsCompleted = null;
  let hasAnAppBeenCreated = null;
  let grantedPermissions = null;
  let intercomHash = null;

  const nameFromLocalStorage = getLocalStorge(AuthenticationResponse.Name);

  if (nameFromLocalStorage !== null) {
    partnerID = getLocalStorge(AuthenticationResponse.PartnerID);
    name = nameFromLocalStorage;
    email = getLocalStorge(AuthenticationResponse.Email);
    role =
      getLocalStorge(AuthenticationResponse.Role) === 'null'
        ? null
        : parseInt(getLocalStorge(AuthenticationResponse.Role) || '0');
    partnerToken = getLocalStorge(AuthenticationResponse.PartnerToken);
    token_expiry_date = getLocalStorge(AuthenticationResponse.Token_expiry_date);
    apps_types = getLocalStorge(AuthenticationResponse.AppsTypes);
    isAccountOwner = isValueTrue(getLocalStorge(AuthenticationResponse.IsAccountOwner) || 'false');
    areAccountStepsCompleted = isValueTrue(getLocalStorge('areAccountCreationStepsCompleted') || 'false');
    hasAnAppBeenCreated = isValueTrue(getLocalStorge('hasAnAppBeenCreated') || 'false');
    grantedPermissions = JSON.parse(getLocalStorge('grantedPermissions') || '[]');
    intercomHash = getLocalStorge(AuthenticationResponse.IntercomHash);
  } else {
    const sessionStorageData = getLocalStorge('sessionStorage');
    const permissionsSessionStorageData = getLocalStorge('permissionsSessionStorage');
    if (sessionStorageData !== null) refillSessionStorage(sessionStorageData);
    if (permissionsSessionStorageData !== null) refillSessionStorage(permissionsSessionStorageData);

    partnerID = getSessionStorge(AuthenticationResponse.PartnerID);
    name = getSessionStorge(AuthenticationResponse.Name);
    email = getSessionStorge(AuthenticationResponse.Email);
    role =
      getSessionStorge(AuthenticationResponse.Role) === 'null'
        ? null
        : parseInt(getSessionStorge(AuthenticationResponse.Role) || '0');
    partnerToken = getSessionStorge(AuthenticationResponse.PartnerToken);
    token_expiry_date = getSessionStorge(AuthenticationResponse.Token_expiry_date);
    apps_types = getSessionStorge(AuthenticationResponse.AppsTypes);
    isAccountOwner = isValueTrue(getSessionStorge(AuthenticationResponse.IsAccountOwner) || 'false');
    areAccountStepsCompleted = isValueTrue(getSessionStorge('areAccountCreationStepsCompleted') || 'false');
    hasAnAppBeenCreated = isValueTrue(getSessionStorge('hasAnAppBeenCreated') || 'false');
    grantedPermissions = JSON.parse(getSessionStorge('grantedPermissions') || '[]');
    intercomHash = getSessionStorge(AuthenticationResponse.IntercomHash);
  }

  return {
    partnerID,
    name,
    email,
    role,
    partnerToken,
    token_expiry_date,
    apps_types,
    isAccountOwner,
    areAccountStepsCompleted,
    hasAnAppBeenCreated,
    grantedPermissions,
    intercomHash,
  };
};

export const isPartnerAuthenticatedLocally = () => {
  const { name, email, partnerToken, token_expiry_date } = getValuesFromStorage();
  if (name && email && partnerToken && token_expiry_date) {
    if (isTokenDateValid(token_expiry_date)) return true;
    else {
      resetStorageAndGlobalStateAuth();
      resetStorageAndGlobalStatePermissions();
      return false;
    }
  }
  return false;
};

export const updateGlobalStorageWithLocal = () => {
  const {
    partnerID,
    name,
    email,
    role,
    partnerToken,
    token_expiry_date,
    apps_types,
    isAccountOwner,
    areAccountStepsCompleted,
    hasAnAppBeenCreated,
    grantedPermissions,
    intercomHash,
  } = getValuesFromStorage();

  store.dispatch('setPartnerID', Number(partnerID));
  setPartnerName(name);
  setPartnerEmail(email);
  setPartnerRole(role);
  setXPartnerTokenState(partnerToken);
  setTokenExpirationDate(token_expiry_date);
  if (apps_types) {
    setPartnerAppsTypes(JSON.parse(apps_types));
  }
  setPartnerAuthenticated(true);
  setIsAccountOwner(isAccountOwner);
  setAccountCreationStepsState(areAccountStepsCompleted);
  setAnAppHasBeenCreated(hasAnAppBeenCreated);
  setPartnerIntercomHash(intercomHash);
  store.dispatch('teamMember/setTeamMemberGrantedPermissions', grantedPermissions);
};

const isValueTrue = (value: string) => {
  return value === 'true' ? true : false;
};

export default useAuthStatus;

const refillSessionStorage = (sessionData: string) => {
  if (sessionData !== null) {
    const data = JSON.parse(sessionData);
    for (const key in data) {
      setSessionStorge(key, data[key]);
    }
  }
};
