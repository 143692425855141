import { MutationTree } from 'vuex';
import { ApplicationType } from './applicationState.type';

export const mutations: MutationTree<ApplicationType> = {
  SET_APP_ID(state, payload) {
    state.appID = payload;
  },
  SET_IS_EDIT_APP_MODE(state, payload) {
    state.isEditMode = payload;
  },
  SET_TYPE_FOR_APP_BEING_CREATED(state, payload) {
    state.appType = payload;
  },
  ADD_CREATE_APP_STEP_COMPLETED(state, payload) {
    state.stepsCompletedList.push(payload);
  },
  RESET_STEPS_COMPLETED(state) {
    state.stepsCompletedList = [];
  },
};
