import { parseDate } from '../../helpers/dateOperations';
import ApiErrorResponseValidation from '../../types/models/apiErrorResponseValidation';

export default class BaseModel {
  id?: number;
  status?: string | null;
  message?:
    | {
        type?: string | null;
        code?: string | null | Record<string, unknown>;
        name?: string | null;
        description?: string | null;
        validations?: ApiErrorResponseValidation[] | null;
      }
    | string
    | null;
  created_at?: string | null;
  updated_at?: string | null;
  constructor(arg: any) {
    if (arg) {
      this.id = arg.id;
      this.status = arg.status;
      this.message = arg.message;
      this.created_at = parseDate(arg.created_at);
      this.updated_at = parseDate(arg.uodated_at);
    }
  }
}
