import Vue from 'vue';
import I18nLocaleEnum from './i18nLocale.enum';
import VueI18n from 'vue-i18n';
import arData from './data/ar.json';
import enData from './data/en.json';

const i18nCreate = (locale: I18nLocaleEnum) => {
  Vue.use(VueI18n);

  const i18n = new VueI18n({
    locale,
    messages: {
      [I18nLocaleEnum.ar]: arData,
      [I18nLocaleEnum.en]: enData,
    },
  });

  return i18n;
};

export default i18nCreate;
