<template>
  <div class="notification">
    <transition name="notification" mode="out-in">
      <div
        v-if="showNotification"
        :class="`w-full flex px-4 py-6 rounded-xl shadow-md border border-1 z-40
                    ${
                      notificationType === NotificationTypeEnum.Rejected
                        ? 'bg-yellow-50 border-red-400'
                        : 'bg-white-pureWhite border-green-400'
                    } 
                    ${classes}`"
      >
        <div class="mt-1 me-4 w-1/12 flex justify-center items-start">
          <div v-if="notificationType === NotificationTypeEnum.Approved" class="p-2 bg-green-900 rounded-full">
            <font-awesome-icon icon="check" :size="iconSize" color="white" class="m-1 mb-0" />
          </div>
          <div v-else class="px-3 py-2 bg-red-700 rounded-full">
            <font-awesome-icon icon="exclamation" :size="iconSize" color="white" class="m-1 mb-0" />
          </div>
        </div>
        <div class="w-full flex relative items-center">
          <!-- logic suspended -->
          <!-- <div @click="onExitIconClicked">
            <base-icon iconName="exit" class="absolute top-0 right-0 cursor-pointer">
              <exit-icon />
            </base-icon>
          </div> -->
          <div class="w-full flex flex-col">
            <div class="flex flex-col mb-1">
              <base-text textStyle="p4" classes="mb-1">
                {{ dayjs(notificationDateTimeCreated).format('YYYY-MM-DD hh:mm a') }}
              </base-text>
              <base-text textStyle="h4"> Hello {{ partnerName }}, </base-text>
            </div>
            <base-text textStyle="h5">
              {{ notificationBody }}
            </base-text>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import BaseIcon from '../../components/base-icon/BaseIcon.vue';
import ExitIcon from '../../assets/icons/notification/ExitIcon.vue';
import BaseText from '../text/BaseText.vue';
import dayjs from 'dayjs';
import { NotificationTypeEnum } from '@/types/components/Notification.type';

export default defineComponent({
  props: {
    notificationType: {
      type: 'approved' | 'rejected',
      required: true,
    },
    partnerName: {
      type: String,
      required: true,
    },
    notificationBody: {
      type: String,
      required: true,
    },
    notificationDateTimeCreated: {
      type: String,
      required: true,
    },
    iconSize: {
      type: 'sm' | 'md',
      default: 'sm',
    },
    classes: {
      type: String,
      default: '',
    },
  },
  components: {
    BaseIcon,
    ExitIcon,
    BaseText,
  },
  setup() {
    const showNotification = ref(true);
    // logic suspended
    // const onExitIconClicked = () => (showNotification.value = false);
    return {
      showNotification,
      dayjs,
      NotificationTypeEnum,
      // onExitIconClicked,
    };
  },
});
</script>

<style lang="scss" scoped>
.notification {
  &-enter-active {
    animation: slide-in 0.4s ease-in-out forwards;
  }
  &-leave-active {
    animation: slide-out 0.4s ease-in-out forwards;
  }
  @keyframes slide-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes slide-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
