import { MutationTree } from 'vuex';
import ApplicationsType from './applicationsStateType.type';

export const mutations: MutationTree<ApplicationsType> = {
  SET_SEARCHED_APPLICATION_NAME(state, payload) {
    state.searchedApplicationName = payload;
  },
  SET_APPLICATIONS_LIST(state, payload) {
    state.applicationsList = payload;
  },
  SET_FILTERED_APPLICATIONS_LIST(state, payload) {
    state.filteredApplicationsList = payload;
  },
};
