import httpClient from '../../httpClient';
import FullApplicationProgressResponseModel from '../../models/FullApplicationProgressResponseModel';

export const getApplicationData = (applicationID: number) => {
  return httpClient
    .get(`/market/app/${applicationID}`)
    .then((response) => new FullApplicationProgressResponseModel(response))
    .catch((error) => error);
};

export const getApplicationDataV2 = (applicationID: number) => {
  return httpClient
    .get(`/partners/apps/${applicationID}`)
    .then((response) => new FullApplicationProgressResponseModel(response))
    .catch((error) => error);
};
