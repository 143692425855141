import { defineComponent, ref } from '@vue/composition-api';
import useIcons from '@/components/sidebar/sidebar-icons/useIcons';
import BaseText from '@/components/text/BaseText.vue';
import SidebarItem from '@/components/sidebar/SidebarItem.vue';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { METABASE_APPS_ANALYTICS_FINANCE } from '@/helpers/constantLinks';

const ICONS = useIcons();

export default defineComponent({
  name: 'financeSidebarMenu',
  components: {
    ...ICONS,
    BaseText,
    SidebarItem,
  },
  setup() {
    const showAppsSubMenu = ref(false);
    const showShippingSubMenu = ref(false);
    const toggleAppsSubMenu = () => (showAppsSubMenu.value = !showAppsSubMenu.value);
    const toggleShippingSubmenu = () => (showShippingSubMenu.value = !showShippingSubMenu.value);

    return {
      showAppsSubMenu,
      showShippingSubMenu,
      RoutingRouteEnum,
      METABASE_APPS_ANALYTICS_FINANCE,
      toggleAppsSubMenu,
      toggleShippingSubmenu,
    };
  },
});
