<template>
  <component v-if="['h1', 'h2', 'h3', 'h4', 'h5'].includes(textStyle)" :is="textStyle">
    <slot></slot>
  </component>
  <p v-else>
    <slot></slot>
  </p>
</template>
<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import { TextStylesTypes } from '../../types/components/text/textTypes';

export default defineComponent({
  props: {
    textStyle: {
      type: String as PropType<TextStylesTypes>,
      default: 'p1',
    },
  },
});
</script>
