import Vue from 'vue';
import Vuex from 'vuex';
import rootActions from './actions';
import rootMutations from './mutations';
import rootGetters from './getters';
import modules from './modules';
Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    isLoading: false,
    partnerID: -1,
  },
  mutations: rootMutations,
  actions: rootActions,
  getters: rootGetters,
  modules,
});
