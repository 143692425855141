import AuthenticationModule from './modules/authentication';
import ApplicationModule from './modules/application';
import ApplicationsModule from './modules/applications';
import ShippingModule from './modules/shipping';
import teamMemberModule from './modules/team-member';

const modules = {
  authentication: AuthenticationModule,
  application: ApplicationModule,
  applications: ApplicationsModule,
  shipping: ShippingModule,
  teamMember: teamMemberModule,
};

export default modules;
