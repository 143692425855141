import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import NotificationsResponseModel from '@/api/models/NotificationsResponseModel';

const ENDPOINT_URL = '/market/notifications';

export const getNotificationsList = (): Promise<NotificationsResponseModel | ErrorModel> => {
  return httpClient
    .get(ENDPOINT_URL)
    .then((response) => new NotificationsResponseModel(response))
    .catch((error) => error);
};
