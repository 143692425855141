import { RouteConfig } from 'vue-router';
import { RoutingRouteEnum } from '../routes.enum';

export const PD_ADMIN_ROUTES: Array<RouteConfig> = [
  {
    path: '/admin-apps-management',
    name: RoutingRouteEnum.Admin_AppsManagement,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminApps" */ '@/domains/pd-admin/views/administration/apps-management/AppsManagement.vue'
      ),
  },
  {
    path: '/admin-app-details',
    name: RoutingRouteEnum.Admin_AppDetailsContainer,
    component: () =>
      import(
        /* webpackChunkName: "adminApps" */ '@/domains/pd-admin/views/administration/apps-management/app-details/AppDetailsContainer.vue'
      ),
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    children: [
      {
        path: 'oauth-app-details/:id',
        name: RoutingRouteEnum.Admin_AppDetails_OAuth,
        component: () =>
          import(
            /* webpackChunkName: "adminApps" */ '@/domains/pd-admin/views/administration/apps-management/app-details/oauth-app/OAuthAppDetails.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
      },
      {
        path: 'js-app-details/:id',
        name: RoutingRouteEnum.Admin_AppDetails_JS,
        component: () =>
          import(
            /* webpackChunkName: "adminApps" */ '@/domains/pd-admin/views/administration/apps-management/app-details/javascript-app/JSAppDetails.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
      },
      {
        path: 'shipping-app-details/:id',
        name: RoutingRouteEnum.Admin_AppDetails_Shipping,
        component: () =>
          import(
            /* webpackChunkName: "adminApps" */ '@/domains/pd-admin/views/administration/apps-management/app-details/shipping-app/ShippingAppDetails.vue'
          ),
        meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
      },
    ],
  },
  {
    path: '/admin-app-webhooks/:appId/:groupId?',
    name: RoutingRouteEnum.Admin_AppDetails_Webhooks,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminApps" */ '@/domains/pd-admin/views/administration/apps-management/app-details/app-webhooks/AppWebhooks.vue'
      ),
  },
  {
    path: '/admin-partners-management',
    name: RoutingRouteEnum.Admin_PartnersManagement,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminPartners" */ '@/domains/pd-admin/views/administration/partners-management/PartnersManagement.vue'
      ),
  },
  {
    path: '/admin-partner-details/:id',
    name: RoutingRouteEnum.Admin_PartnerDetails,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminPartners" */ '@/domains/pd-admin/views/administration/partners-management/partner-details/PartnerDetails.vue'
      ),
  },
  {
    path: '/admin-sections-management',
    name: RoutingRouteEnum.Admin_SectionsManagement,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminSections" */ '@/domains/pd-admin/views/administration/sections-management/SectionsManagement.vue'
      ),
  },
  {
    path: '/admin-apps-collections',
    name: RoutingRouteEnum.Admin_AppsCollections,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminControl" */ '@/domains/pd-admin/views/administration/apps-collections/collections-list/CollectionsList.vue'
      ),
  },
  {
    path: '/admin-manage-collection/:id?',
    name: RoutingRouteEnum.Admin_ManageCollection,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminControl" */ '@/domains/pd-admin/views/administration/apps-collections/manage-collection/ManageCollection.vue'
      ),
  },
  {
    path: '/admin-section-details-management/:id?',
    name: RoutingRouteEnum.Admin_SectionDetailsManagement,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminSections" */ '@/domains/pd-admin/views/administration/sections-management/section-details/SectionDetails.vue'
      ),
  },
  {
    path: '/admin-featured-apps',
    name: RoutingRouteEnum.Admin_FeaturedApps,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminControl" */ '@/domains/pd-admin/views/administration/featured-apps/FeaturedApps.vue'
      ),
  },
  {
    path: '/admin-suggested-apps',
    name: RoutingRouteEnum.Admin_SuggestedApps,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminControl" */ '@/domains/pd-admin/views/administration/suggested-apps/SuggestedApps.vue'
      ),
  },
  {
    path: '/admin-private-apps',
    name: RoutingRouteEnum.Admin_PrivateApps,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminPrivateApps" */ '@/domains/pd-admin/views/administration/private-apps/PrivateApps.vue'
      ),
  },
  {
    path: '/admin-ratings-and-reviews',
    name: RoutingRouteEnum.Admin_RatingsAndReviews,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminControl" */ '@/domains/pd-admin/views/administration/ratings-and-reviews/RatingsAndReviews.vue'
      ),
  },
  {
    path: '/admin-my-apps',
    name: RoutingRouteEnum.Admin_MyApps,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () => import(/* webpackChunkName: "adminControl" */ '@/domains/pd-admin/views/my-apps/MyApps.vue'),
  },
  // create js app
  {
    path: '/admin-create-application',
    name: RoutingRouteEnum.Admin_CreateApplication,
    component: () =>
      import(
        /* webpackChunkName: "adminCreateApp" */ '@/domains/pd-admin/views/my-apps/create-js-app/CreateJSApplication.vue'
      ),
    meta: { requireAuth: true, requireAccountStepsCompletion: false, adminCreateApplicationStep: true, isAdmin: true },
  },
  {
    path: '/admin-create-application-steps/:id',
    name: RoutingRouteEnum.Admin_CreateApplication_StepsContainer,
    component: () =>
      import(
        /* webpackChunkName: "adminCreateApp" */ '@/domains/pd-admin/views/my-apps/create-js-app/create-steps/CreateStepsContainer.vue'
      ),
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    children: [
      {
        path: 'general-settings',
        name: RoutingRouteEnum.Admin_CreateApplication_GeneralSettings_Javascript,
        component: () =>
          import(
            /* webpackChunkName: "adminCreateApp" */ '@/domains/pd-admin/views/my-apps/create-js-app/create-steps/general-settings/GeneralSettingsJavascript.vue'
          ),
        meta: {
          requireAuth: true,
          requireAccountStepsCompletion: false,
          adminCreateApplicationStep: true,
          isAdmin: true,
        },
      },
      {
        path: 'application-details',
        name: RoutingRouteEnum.Admin_CreateApplication_AppDetails,
        component: () =>
          import(
            /* webpackChunkName: "adminCreateApp" */ '@/domains/pd-admin/views/my-apps/create-js-app/create-steps/app-details/ApplicationDetails.vue'
          ),
        meta: {
          requireAuth: true,
          requireAccountStepsCompletion: false,
          adminCreateApplicationStep: true,
          isAdmin: true,
        },
      },
      {
        path: 'webhook-management',
        name: RoutingRouteEnum.Admin_CreateApplication_WebhookManagement,
        component: () =>
          import(
            /* webpackChunkName: "adminCreateApp" */ '@/domains/pd-admin/views/my-apps/create-js-app/create-steps/webhook-management/WebhookManagement.vue'
          ),
        meta: {
          requireAuth: true,
          requireAccountStepsCompletion: false,
          adminCreateApplicationStep: true,
          isAdmin: true,
        },
      },
      {
        path: 'plans-management',
        name: RoutingRouteEnum.Admin_CreateApplication_PlansManagement,
        component: () =>
          import(
            /* webpackChunkName: "adminCreateApp" */ '@/domains/pd-admin/views/my-apps/create-js-app/create-steps/plans-management/PlansManagement.vue'
          ),
        meta: {
          requireAuth: true,
          requireAccountStepsCompletion: false,
          adminCreateApplicationStep: true,
          isAdmin: true,
        },
      },
      {
        path: 'publish',
        name: RoutingRouteEnum.Admin_CreateApplication_Publish,
        component: () =>
          import(
            /* webpackChunkName: "adminCreateApp" */ '@/domains/pd-admin/views/my-apps/create-js-app/create-steps/publish/Publish.vue'
          ),
        meta: {
          requireAuth: true,
          requireAccountStepsCompletion: false,
          adminCreateApplicationStep: true,
          isAdmin: true,
        },
      },
    ],
  },
  // create js app
  {
    path: '/admin-scripts',
    name: RoutingRouteEnum.Admin_Scripts,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminScripts" */ '@/domains/pd-admin/views/administration/js-apps/scripts-list/ScriptsList.vue'
      ),
  },
  {
    path: '/admin-script-history/:id/:appID',
    name: RoutingRouteEnum.Admin_ScriptHistory,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminScripts" */ '@/domains/pd-admin/views/administration/js-apps/script-history/ScriptHistory.vue'
      ),
  },
  {
    path: '/admin-app-script-history/:appID',
    name: RoutingRouteEnum.Admin_AppScriptHistory,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "adminScripts" */ '@/domains/pd-admin/views/administration/js-apps/all-app-scripts-history/AllAppScriptsHistory.vue'
      ),
  },
  {
    path: '/admin-manage-pw-categories',
    name: RoutingRouteEnum.Admin_ManagePWCategories,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(/* webpackChunkName: "adminPW" */ '@/domains/pd-admin/views/zam-website/categories/ManageCategories.vue'),
  },
  {
    path: '/admin-manage-pw-faqs',
    name: RoutingRouteEnum.Admin_ManagePWFAQs,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isAdmin: true },
    component: () =>
      import(/* webpackChunkName: "adminPW" */ '@/domains/pd-admin/views/zam-website/faqs/ManageFAQs.vue'),
  },
];
