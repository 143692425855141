import store from '../../../store/index';
import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../../routes.enum';
import { useState } from 'vuex-composition-helpers';
import { PartnerRoleEnum } from '@/types/models/partner.type';

export const useAccountCompletionGuard = async (to: Route, from: Route, next: any) => {
  const { hasAnAppBeenCreated, areAccountCreationStepsCompleted, role, apps_types } = await useState(
    store,
    'authentication',
    ['hasAnAppBeenCreated', 'areAccountCreationStepsCompleted', 'role', 'apps_types'],
  );
  if (to.name === RoutingRouteEnum.Home) {
    switch (role.value) {
      case null: {
        if (hasAnAppBeenCreated.value && areAccountCreationStepsCompleted.value) {
          if (apps_types.value.shipping_apps && !apps_types.value.public_apps) {
            next({ name: RoutingRouteEnum.ShippingDashboard });
            return;
          }
          next({ name: RoutingRouteEnum.Dashboard });
        } else next();
        return;
      }
      case PartnerRoleEnum.PartnershipAdmin: {
        next({ name: RoutingRouteEnum.ManagePartnerships_Apps });
        return;
      }
      case PartnerRoleEnum.Admin: {
        next({ name: RoutingRouteEnum.Admin_AppsManagement });
        return;
      }
      default:
        next(false);
        return;
    }
  } else if (to.matched.some((route) => route.meta.requireAccountStepsCompletion)) {
    if (areAccountCreationStepsCompleted.value) {
      next();
      return;
    } else next({ name: RoutingRouteEnum.Home });
  } else next();
};
