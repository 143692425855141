<template>
  <text-element
    :class="`font-IBMPlexSansArabic text-${textStyle} font-${font_weights[textStyle].sm} leading-${textStyle} 
                       md:text-${textStyle} md:font-${font_weights[textStyle].md} md:leading-${textStyle} 
                       lg:text-${textStyle} lg:font-${font_weights[textStyle].lg} lg:leading-${textStyle} 
                       ${!!classes ? classes : ''}`"
    :textStyle="textStyle"
  >
    <slot> </slot>
  </text-element>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from '@vue/composition-api';
import { FONT_WEIGHTS } from './text-utils/textUtils';
import { TextStylesTypes } from '../../types/components/text/textTypes';
import TextElement from './TextElement.vue';

export default defineComponent({
  name: 'BaseText',
  props: {
    textStyle: {
      type: String as PropType<TextStylesTypes>,
      default: 'p1',
    },
    classes: {
      type: String,
    },
  },
  components: {
    TextElement,
  },
  setup() {
    const font_weights = ref(FONT_WEIGHTS);
    return { font_weights };
  },
});
</script>
