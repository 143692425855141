export enum PagesNamesEnum {
  Home = 'Welcome Page',
  Dashboard = 'Applications Dashboard Page',
  ShippingDashboard = 'Shipping Dashboard Page',
  ThemesDashboard = 'Themes Dashboard Page',
  ThemesManagement = 'Themes Management Page',
  ManageMerchantThemeReviews = 'Manage Merchant Theme Reviews Page',
  ThemeInfoContainer = 'Theme Information Page',
  ThemesPurchaseList = 'Themes Purchases Page',
  Subscriptionslist = 'Subscriptions Page',
  OrdersManagement = 'Orders Management Page',
  ActivationsManagement = 'activations Management Page',
  LogIn = 'Login Page',
  Logout = 'Logout Page',
  Register = 'Registration Page',
  ForgotPassword = 'Forgot Password Page',
  ChangePassword = 'Change Password Page',
  VerifyEmail = 'Verify Email Page',
  Applications = 'Applications Page',
  CreateApplication = 'Application Page',
  CreateApplication_StepsContainer = 'Application Page',
  CreateApplication_GeneralSettings_Normal = 'General settings Page',
  CreateApplication_AppDetails = 'App Details Page',
  CreateApplication_WebhookManagement = 'Webhook Management Page',
  CreateApplication_PlansManagement = 'Plans Management Page',
  ManagePrivatePlans = 'Private Plans Page',
  ManageMerchantAppsReviews = 'Manage Merchant Reviews Page',
  CreateApplication_Publish = 'Publish App Page',
  CreateShippingApplication = 'Shipping Application Page',
  CreateShippingApplication_StepsContainer = 'Shipping Application Page',
  CreateShippingApplication_CompanyInfo = 'Shipping Company Info Page',
  CreateShippingApplication_GeneralSettings = 'Shipping Settings Page',
  CreateShippingApplication_AboutCompany = 'Shipping Company Page',
  CreateShippingApplication_ShippingOptionDetails = 'Shipping Details Page',
  CreateShippingApplication_WebhookManagement = 'Shipping Webhook Management Page',
  CreateShippingApplication_Publish = 'Publish Shipping App Page',
  DevelopmentStore = 'Development Stores Page',
  WebhookLogs = 'Webhook Logs Page',
  Help = 'Help Page',
  Notifications = 'Notifications Page',
  BankAccount = 'Bank Account Page',
  PartnerCoupons = 'Coupons Page',
  Profile = 'Profile Page',
  TeamMembers = 'Team Members Page',
  MemberDetails = 'Team Member Details Page',
  partnershipDetails = 'Partnership Details Page',
  Payouts = 'Payouts Page',
  NotFound = 'Not Found Page',
  NotAuthorized = 'Not Authorized Page',
  CLI_Authorized = 'Authorization Successful Page',
  ManagePartnerships_Apps = 'Partnerships Page',
  ManagePartnerships_Themes = 'Themes Partnerships Page',
  ManagePartnerships_PartnerContainer = 'Partnerships Page',
  ManagePartnerships_Partner = 'Partner Partnerships Page',
  Admin_AppsManagement = 'Apps Management Page',
  Admin_AppDetailsContainer = 'App Details Page',
  Admin_AppDetails_OAuth = 'Oauth App Page',
  Admin_AppDetails_JS = 'JS App Page',
  Admin_AppDetails_Shipping = 'Shipping App Page',
  Admin_AppDetails_Webhooks = 'App Webhooks Page',
  Admin_PartnersManagement = 'Partners Management Page',
  Admin_PartnerDetails = 'Partner Details Page',
  Admin_SectionsManagement = 'Sections Management Page',
  Admin_AppsCollections = 'Apps Collections Page',
  Admin_ManageCollection = 'Manage Collection Page',
  Admin_SectionDetailsManagement = 'Section Management Page',
  Admin_FeaturedApps = 'Featured Apps Page',
  Admin_SuggestedApps = 'Suggested Apps Page',
  Admin_PrivateApps = 'Private Apps Page',
  Admin_RatingsAndReviews = 'Ratings And Reviews Page',
  Admin_MyApps = 'My Apps Page',
  Admin_CreateApplication = 'Application Page',
  Admin_CreateApplication_StepsContainer = 'Application Page',
  Admin_CreateApplication_GeneralSettings_Javascript = 'Application Settings Page',
  Admin_CreateApplication_AppDetails = 'App Details Page',
  Admin_CreateApplication_WebhookManagement = 'Webhook Management Page',
  Admin_CreateApplication_PlansManagement = 'Plans Management Page',
  Admin_CreateApplication_Publish = 'publish App Page',
  Admin_Scripts = 'Admin Scripts Page',
  Admin_ScriptHistory = 'Script History Page',
  Admin_AppScriptHistory = 'App Script History Page',
  Admin_ManagePWCategories = 'PW Categories Page',
  Admin_ManagePWFAQs = 'PW FAQs Page',
  FinanceAdmin_AppsSubscriptions = 'Apps Subscriptions Page',
  FinanceAdmin_PartnersManagement = 'Partners Management Page',
  FinanceAdmin_PartnerDetails = 'Partner Details Page',
  FinanceAdmin_ShippingOrders = 'Orders Management Page',
  FinanceAdmin_ShippingActivations = 'Activations Management Page',
  FinanceAdmin_Payouts = 'Payouts Page',
}
