import BaseModel from './BaseModel';
import ApiErrorResponseValidation from '../../types/models/apiErrorResponseValidation';

class ErrorModel extends BaseModel {
  public validations: ApiErrorResponseValidation[];
  public message: {
    type?: string | null;
    code?: string | null | Record<string, unknown>;
    name?: string | null;
    description?: string | null;
    validations?: ApiErrorResponseValidation[] | null;
  };

  constructor(arg: any) {
    super(arg);
    if (arg) this.validations = arg.message?.validations;
    else this.validations = [];
    this.message = arg.message;
  }
}

export default ErrorModel;
