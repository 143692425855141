import { ActionTree } from 'vuex';
import { ApplicationType } from './applicationState.type';

export const actions: ActionTree<ApplicationType, unknown> = {
  setAppID({ commit }, appID) {
    commit('SET_APP_ID', appID);
  },
  setIsEditMode({ commit }, isEditMode) {
    commit('SET_IS_EDIT_APP_MODE', isEditMode);
  },
  setTypeForApplicationBeingCreated({ commit }, appTypeBeingCreated) {
    commit('SET_TYPE_FOR_APP_BEING_CREATED', appTypeBeingCreated);
  },
  addStepCompleted({ commit }, stepCompleted) {
    commit('ADD_CREATE_APP_STEP_COMPLETED', stepCompleted);
  },
  resetStepsCompleted({ commit }) {
    commit('RESET_STEPS_COMPLETED');
  },
};
