import { GetterTree } from 'vuex';
import { ApplicationType } from './applicationState.type';

export const getters: GetterTree<ApplicationType, any> = {
  isLoadingAppData: (state) => state.loadingAppData,
  createAppProcess: (state) => state.createApp,
  stepsCompleted: (state) => state.createApp.stepsCompletedList,
  inProgressFullApp: (state) => state.createApp.inProgressFullApp,
  shippingAppGeneralData: (state) => state.createApp.shippingAppGeneralData,
};
