import Vue from 'vue';
import store from '../../../store/index';
import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../../routes.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { getApplicationDataV2 } from '../../../api/top-level-apis/application/application';
import ErrorModel from '@/api/models/ErrorModel';
import { ApplicationListingStatusProperty } from '@/api/models/ApplicationModel';

const { useActions } = createNamespacedHelpers(store, 'application');

const GENERAL_SETTINGS = 1;
const APPLICATION_DETAILS = 2;
const WEBHOOK_MANAGEMENT = 3;
const PLANS_MANAGEMENT = 4;
const REQUEST_TO_PUBLISH = 5;

export const useRedirectToCreateAppStep = async (to: Route, from: Route, next: any) => {
  const { setAppID, setIsEditMode, setTypeForApplicationBeingCreated, addStepCompleted, resetStepsCompleted } =
    useActions([
      'setAppID',
      'setIsEditMode',
      'setTypeForApplicationBeingCreated',
      'addStepCompleted',
      'resetStepsCompleted',
    ]);

  if (from.name === null && to.matched.some((route) => route.meta.createApplicationStep)) {
    resetStepsCompleted();

    if (to.name === RoutingRouteEnum.CreateApplication) {
      next();
      return;
    } else next({ name: RoutingRouteEnum.CreateApplication });
  } else if (
    ((from.name === RoutingRouteEnum.CreateApplication ||
      from.matched.some((route) => route.meta.isCreateAppStepExtension)) &&
      to.name === RoutingRouteEnum.CreateApplication_GeneralSettings_Normal) ||
    to.name === RoutingRouteEnum.CreateApplication_StepsContainer
  ) {
    const applicationId = to.query.appId;
    if (typeof applicationId === 'string' || typeof applicationId === 'number') {
      const response = await getApplicationDataV2(parseInt(applicationId));

      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Could not find application!');
        next({ name: RoutingRouteEnum.Applications });
        return;
      }

      setAppID(parseInt(applicationId));

      const { payload } = response;
      const { steps, type } = payload.app;
      let localSteps = steps;
      const isEditMode = payload.app.listing_status === ApplicationListingStatusProperty.Published;
      setIsEditMode(isEditMode);
      if (isEditMode) {
        localSteps = PLANS_MANAGEMENT;
      }
      // Redirect if the app is in review
      if (payload.app.listing_status === ApplicationListingStatusProperty.RequestToPublish) {
        Vue.$toast.error('You have no permission to view the application');
        next({ name: RoutingRouteEnum.Applications });
        return;
      }

      setTypeForApplicationBeingCreated(type === 0 ? 'normal' : 'javascript');
      switch (localSteps) {
        case GENERAL_SETTINGS:
          next({
            name: RoutingRouteEnum.CreateApplication_GeneralSettings_Normal,
          });
          return;
        case APPLICATION_DETAILS:
          addStepCompleted(RoutingRouteEnum.CreateApplication_AppDetails);
          next({
            name: RoutingRouteEnum.CreateApplication_AppDetails,
          });
          return;
        case WEBHOOK_MANAGEMENT:
          addStepCompleted(RoutingRouteEnum.CreateApplication_AppDetails);
          addStepCompleted(RoutingRouteEnum.CreateApplication_WebhookManagement);
          addStepCompleted(RoutingRouteEnum.CreateApplication_PlansManagement);
          addStepCompleted(RoutingRouteEnum.CreateApplication_Publish);
          next({
            name: RoutingRouteEnum.CreateApplication_WebhookManagement,
          });
          return;
        case PLANS_MANAGEMENT:
          addStepCompleted(RoutingRouteEnum.CreateApplication_AppDetails);
          addStepCompleted(RoutingRouteEnum.CreateApplication_WebhookManagement);
          addStepCompleted(RoutingRouteEnum.CreateApplication_PlansManagement);
          addStepCompleted(RoutingRouteEnum.CreateApplication_Publish);
          next({
            name: RoutingRouteEnum.CreateApplication_PlansManagement,
          });
          return;
        case REQUEST_TO_PUBLISH:
          addStepCompleted(RoutingRouteEnum.CreateApplication_AppDetails);
          addStepCompleted(RoutingRouteEnum.CreateApplication_WebhookManagement);
          addStepCompleted(RoutingRouteEnum.CreateApplication_PlansManagement);
          addStepCompleted(RoutingRouteEnum.CreateApplication_Publish);
          if (payload.app.listing_status === ApplicationListingStatusProperty.Published) {
            next({
              name: RoutingRouteEnum.CreateApplication_PlansManagement,
            });
            return;
          } else {
            next({
              name: RoutingRouteEnum.CreateApplication_Publish,
            });
            return;
          }
        default:
          break;
      }
    }
  } else if (
    from.name !== RoutingRouteEnum.Home &&
    from.name !== RoutingRouteEnum.Applications &&
    from.name !== RoutingRouteEnum.CreateApplication_StepsContainer &&
    !from.matched.some((route) => route.meta.isCreateAppStepExtension) &&
    !from.matched.some((route) => route.meta.createApplicationStep) &&
    to.matched.some((route) => route.meta.createApplicationStep)
  ) {
    next({ name: RoutingRouteEnum.Applications });
  } else next();
};
