export enum NotificationTypeEnum {
  Approved = 'approved',
  Rejected = 'rejected',
}

type NotificationType = {
  id: number;
  partner_id: number;
  app_id: number;
  body: string;
  is_seen: 0 | 1;
  created_at: string;
  listing_status: NotificationTypeEnum;
};

export default NotificationType;
