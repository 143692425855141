import { render, staticRenderFns } from "./NotificationsDropdown.vue?vue&type=template&id=a8f64996&scoped=true"
import script from "./NotificationsDropdown.vue?vue&type=script&lang=ts"
export * from "./NotificationsDropdown.vue?vue&type=script&lang=ts"
import style0 from "./NotificationsDropdown.vue?vue&type=style&index=0&id=a8f64996&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8f64996",
  null
  
)

export default component.exports