<template>
  <div class="md:flex flex-col md:flex-row md:min-h-screen w-full">
    <div class="flex flex-col px-0.5 w-full md:w-64 text-gray-700 bg-primary-700 flex-shrink-0">
      <div class="flex-shrink-0 py-5 px-3 flex flex-row items-center justify-between">
        <!-- zid logo -->
        <router-link to="/" class="mx-1 flex items-center">
          <img class="me-2" src="../../assets/logo.png" width="36" alt="zid logo" />
          <base-text textStyle="h4" class="text-white-pureWhite"> Partner Dashboard </base-text>
        </router-link>
        <!-- zid logo -->
        <!-- sm screen menu toggler -->
        <button class="rounded-lg md:hidden focus:outline-none focus:shadow-outline" @click="toggleMenuOpen">
          <svg fill="white" viewBox="0 0 20 20" class="w-6 h-6">
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <!-- sm screen menu toggler -->
      </div>
      <!-- side menu -->
      <app-sidebar :menuOpen="menuOpen" />
      <!-- side menu -->
    </div>
    <div class="w-full flex flex-col calc-content-width">
      <!-- header -->
      <app-header class="hidden md:flex" />
      <!-- header -->
      <div v-if="isAppLoading" class="w-full h-screen flex justify-center items-center">
        <zid-loader size="large" />
      </div>
      <!-- root route -->
      <transition v-show="!isAppLoading" name="route" mode="out-in">
        <router-view> </router-view>
      </transition>
      <!-- root route -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from '@vue/composition-api';
import AppSidebar from '../sidebar/AppSidebar.vue';
import AppHeader from '../header/AppHeader.vue';
import BaseText from '../text/BaseText.vue';
import { ZidLoader } from '@zidsa/ui';
import { useGetters as rootGetters } from 'vuex-composition-helpers';

export default defineComponent({
  components: {
    AppSidebar,
    AppHeader,
    BaseText,
    ZidLoader,
  },
  setup() {
    const { isAppLoading } = rootGetters(['isAppLoading']);
    const menuOpen = ref(false);
    const toggleMenuOpen = () => (menuOpen.value = !menuOpen.value);

    return {
      menuOpen,
      isAppLoading,
      toggleMenuOpen,
    };
  },
});
</script>

<style lang="scss" scoped>
@import url('../../app.scss');
@media (min-width: 768px) {
  // md:w-64 equals 16rem
  .calc-content-width {
    max-width: calc(100% - 16rem);
  }
}
</style>
