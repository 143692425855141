export enum PartnerRoleEnum {
  Empty = 0,
  Admin = 1,
  Finance = 2,
  PartnershipAdmin = 3,
}

export enum PartnerAppIntentionLabelsEnum {
  EnterpriseMerchant = 'Developing Public App (list on Zid App Market)',
  ZAM = 'Developing Private App (only for Professional and Enterprise packages)',
  ThemeDeveloper = 'Developing Theme (list on Zid Theme Market)',
}

export enum PartnerAppIntentionValuesEnum {
  EnterpriseMerchant = 0,
  ZAM = 1,
  ThemeDeveloper = 2,
}

export enum PartnerLookingForIdeasLabelsEnum {
  No = 'no',
  Yes = 'yes',
}

export enum PartnerLookingForIdeasValuesEnum {
  No = 0,
  Yes = 1,
}

export type PartnerAppsType = {
  shipping_apps: boolean;
  public_apps: boolean;
};
