export const setSessionStorge = (key: string, value: any) => {
  return sessionStorage.setItem(key, value);
};

export const getSessionStorge = (key: string) => {
  return sessionStorage.getItem(key);
};

export const removeSessionStorge = (key: string) => {
  return sessionStorage.removeItem(key);
};
