export enum RootPermissionsEnum {
  Home = 'view.partner.home',
  Dashboard = 'view.partner.dashboard',
  ShippingDashboard = 'view.partner.shipping.dashboard',
  ThemesDashboard = 'view.partner.themes.dashboard',
  ThemesManagement = 'list.partner.themes.management',
  ManageMerchantThemeReviews = 'manage.partner.merchant.theme.reviews',
  ThemeInfoContainer = 'manage.partner.theme.info.container',
  ThemesPurchaseList = 'list.partner.themes.purchase.list',
  Subscriptionslist = 'list.partner.subscriptions.list',
  OrdersManagement = 'list.partner.orders.management',
  ActivationsManagement = 'list.partner.activation.management',
  LogIn = 'manage.login',
  Logout = 'manage.logout',
  Register = 'manage.register',
  ForgotPassword = 'manage.forgot.password',
  ChangePassword = 'manage.change.password',
  VerifyEmail = 'manage.verify.email',
  Applications = 'list.partner.applications',
  CreateApplication = 'manage.partner.create.application',
  CreateApplication_StepsContainer = 'manage.partner.create.application.steps',
  CreateApplication_GeneralSettings_Normal = 'manage.partner.create.application.general',
  CreateApplication_AppDetails = 'manage.partner.create.application.app.details',
  CreateApplication_WebhookManagement = 'manage.partner.create.application.webhook.management',
  CreateApplication_PlansManagement = 'manage.partner.create.application.plans.management',
  ManagePrivatePlans = 'manage.partner.private.plans',
  ManageMerchantAppsReviews = 'manage.partner.merchant.apps.reviews',
  CreateApplication_Publish = 'manage.partner.create.application.publish',
  CreateShippingApplication = 'manage.partner.create.shipping.application',
  CreateShippingApplication_StepsContainer = 'manage.partner.create.shipping.application.steps',
  CreateShippingApplication_CompanyInfo = 'manage.partner.create.shipping.application.company.info',
  CreateShippingApplication_GeneralSettings = 'manage.partner.create.shipping.application.general.settings',
  CreateShippingApplication_AboutCompany = 'manage.partner.create.shipping.application.about.company',
  CreateShippingApplication_ShippingOptionDetails = 'manage.partner.create.shipping.application.shipping.details',
  CreateShippingApplication_WebhookManagement = 'manage.partner.create.shipping.application.webhook.management',
  CreateShippingApplication_Publish = 'manage.partner.create.shipping.application.publish',
  DevelopmentStore = 'manage.partner.development.stores',
  WebhookLogs = 'manage.partner.webhook.logs',
  Help = 'manage.partner.help',
  Notifications = 'list.partner.notifications',
  BankAccount = 'manage.partner.bank.account',
  PartnerCoupons = 'manage.partner.coupons',
  Profile = 'manage.partner.profile',
  TeamMembers = 'list.partner.team.members',
  MemberDetails = 'manage.partner.member.details',
  partnershipDetails = 'manage.partner.partnership.details',
  Payouts = 'manage.partner.payouts',
  NotFound = 'view.not.found',
  NotAuthorized = 'view.not.authorized',
  CLI_Authorized = 'view.cli.authorized',
  ManagePartnerships_Apps = 'manage.partnerships.admin.manage.partnerships.apps',
  ManagePartnerships_Themes = 'manage.partnerships.admin.manage.partnerships.themes',
  ManagePartnerships_PartnerContainer = 'manage.partnerships.admin.partnerships.partner.container',
  ManagePartnerships_Partner = 'manage.partnerships.admin.manage.partnerships.partner',
  Admin_AppsManagement = 'manage.admin.admin.apps.management',
  Admin_AppDetailsContainer = 'manage.admin.admin.app.details.container',
  Admin_AppDetails_OAuth = 'manage.admin.admin.app.details.oauth',
  Admin_AppDetails_JS = 'manage.admin.admin.app.details.js',
  Admin_AppDetails_Shipping = 'manage.admin.admin.app.details.shipping',
  Admin_AppDetails_Webhooks = 'manage.admin.admin.app.details.webhooks',
  Admin_PartnersManagement = 'manage.admin.admin.partners.management',
  Admin_PartnerDetails = 'manage.admin.admin.partner.details',
  Admin_SectionsManagement = 'manage.admin.admin.sections.management',
  Admin_AppsCollections = 'manage.admin.admin.apps.collections',
  Admin_ManageCollection = 'manage.admin.admin.manage.collection',
  Admin_SectionDetailsManagement = 'manage.admin.admin.section.details.management',
  Admin_FeaturedApps = 'manage.admin.admin.featured.apps',
  Admin_SuggestedApps = 'manage.admin.admin.suggested.apps',
  Admin_PrivateApps = 'manage.admin.admin.private.apps',
  Admin_RatingsAndReviews = 'manage.admin.admin.ratings.and.reviews',
  Admin_MyApps = 'list.admin.admin.my.apps',
  Admin_CreateApplication = 'manage.admin.admin.create.application',
  Admin_CreateApplication_StepsContainer = 'manage.admin.admin.create.application.steps',
  Admin_CreateApplication_GeneralSettings_Javascript = 'manage.admin.admin.create.application.general.javascript',
  Admin_CreateApplication_AppDetails = 'manage.admin.admin.create.application.app.details',
  Admin_CreateApplication_WebhookManagement = 'manage.admin.admin.create.application.webhook.management',
  Admin_CreateApplication_PlansManagement = 'manage.admin.admin.create.application.plans.management',
  Admin_CreateApplication_Publish = 'manage.admin.admin.create.application.publish',
  Admin_Scripts = 'manage.admin.admin.scripts',
  Admin_ScriptHistory = 'list.admin.admin.script.history',
  Admin_AppScriptHistory = 'list.admin.admin.app.script.history',
  Admin_ManagePWCategories = 'manage.admin.admin.manage.pw.categories',
  Admin_ManagePWFAQs = 'manage.admin.admin.manage.pw.faqs',
  FinanceAdmin_AppsSubscriptions = 'manage.finance.admin.apps.subscriptions',
  FinanceAdmin_PartnersManagement = 'list.finance.admin.partners.management',
  FinanceAdmin_PartnerDetails = 'manage.finance.admin.partner.details',
  FinanceAdmin_ShippingOrders = 'manage.finance.admin.shipping.orders',
  FinanceAdmin_ShippingActivations = 'manage.finance.admin.shipping.activations',
  FinanceAdmin_Payouts = 'manage.finance.admin.payouts',
}
