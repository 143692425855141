import { RoutingRouteEnum } from '@/router/routes.enum';
import { Route } from 'vue-router';

export const useExtensionAppStep = (to: Route, from: Route, next: any) => {
  if (
    from.matched.some((route) => route.meta.isCreateAppStepExtension) &&
    to.matched.some((route) => route.meta.createApplicationStep)
  ) {
    next({
      name: RoutingRouteEnum.CreateApplication_StepsContainer,
      query: {
        appId: from.params.id,
      },
    });
  } else next();
};
