import I18nLocaleEnum from './i18nLocale.enum';
import appI18nCreate from './i18nCreate';
import getSelectedLocaleFromLocalStorage from './localeStorage';

const locale: string = getSelectedLocaleFromLocalStorage();

const i18n = appI18nCreate(I18nLocaleEnum[locale as keyof typeof I18nLocaleEnum]);

const i18nFactory = (): typeof i18n => i18n;

export default i18nFactory;
