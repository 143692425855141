import store from '@/store/index';
import { Route } from 'vue-router';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { emptyCreateAppState } from '@/store/modules/shipping/index';

const { useGetters, useActions } = createNamespacedHelpers(store, 'shipping');

export const useResetCreateShippingAppProcess = (to: Route, from: Route, next: any) => {
  const { createAppProcess } = useGetters(['createAppProcess']);
  const { resetCreateAppProcess } = useActions(['resetCreateAppProcess']);

  if (
    from.matched.some((route) => route.meta.createShippingApplicationStep) &&
    !to.matched.some((route) => route.meta.createShippingApplicationStep)
  ) {
    delete createAppProcess.value.effect;
    const currentState = createAppProcess.value;
    const emptyState = { ...emptyCreateAppState };
    if (JSON.stringify(currentState) !== JSON.stringify(emptyState)) {
      resetCreateAppProcess();
    }
    next();
  } else next();
};
