import httpClient from '../../../api/httpClient';
import LogoutResponseModel from '../models/LogoutResponseModel';

const ENDPOINT_URL = '/market/partner-logout';

export const logoutPartner = () => {
  return httpClient
    .post(ENDPOINT_URL)
    .then((response) => new LogoutResponseModel(response))
    .catch((error) => error);
};
