import { computed, defineComponent } from '@vue/composition-api';
import AppContainer from './components/app-container/AppContainer.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { isPartnerAuthenticatedLocally, updateGlobalStorageWithLocal } from '@/api/useAuthStatus';
import I18nLocaleEnum from '@/helpers/i18n/i18nLocale.enum';
import store from './store';
import { RoutingRouteEnum } from './router/routes.enum';

const { useGetters } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    AppContainer,
  },
  setup(_, ctx) {
    if (isPartnerAuthenticatedLocally()) updateGlobalStorageWithLocal();

    const { isPartnerAuthenticated, role } = useGetters(['isPartnerAuthenticated', 'role']);

    const hideAppContainer = computed(() => {
      return ctx.root.$route.name === RoutingRouteEnum.CLI_Authorized;
    });

    if (isPartnerAuthenticated.value && role.value === null) {
      const { name, email, intercom_hash } = useGetters(['name', 'email', 'intercom_hash']);
      const partnerID = store.getters['partnerID'];

      window.addEventListener('load', function () {
        if (typeof window.Intercom === 'function') {
          window.Intercom('boot', {
            app_id: process.env.VUE_APP_INTERCOM_APP_ID,
            user_hash: intercom_hash.value,
            user_id: `${partnerID}`,
            api_base: 'https://api-iam.intercom.io',
            zam_ID: partnerID,
            name: name.value,
            email: email.value,
            language_override: I18nLocaleEnum.en,
          });
        } else {
          console.error('Intercom script not loaded');
        }
      });
    }

    return {
      isPartnerAuthenticated,
      hideAppContainer,
    };
  },
});
