import { MutationTree } from 'vuex';
import { ApplicationType } from './applicationState.type';

export const mutations: MutationTree<ApplicationType> = {
  SET_IS_LOADING_APP_DATA(state, payload) {
    state.loadingAppData = payload;
  },
  ADD_CREATE_APP_STEP_COMPLETED(state, payload) {
    state.createApp.stepsCompletedList.push(payload);
  },
  REMOVE_CREATE_APP_COMPLETED_STEPS(state, payload) {
    state.createApp.stepsCompletedList = state.createApp.stepsCompletedList.filter((x) => !payload.includes(x));
  },
  // shipping app created, completing steps
  SET_CURRENT_IN_PROGRESS_FULL_APP(state, payload) {
    state.createApp.inProgressFullApp = payload;
  },
  // shipping app created, completing steps
  RESET_CREATE_APP_PROCESS_DATA(state) {
    state.createApp.stepsCompletedList = [];
    state.createApp.inProgressFullApp = null;
    state.createApp.shippingAppGeneralData = null;
  },
  // shipping application general data
  SET_SHIPPING_APP_GENERAL_DATA(state, payload) {
    state.createApp.shippingAppGeneralData = payload;
  },
  // shipping application general data
};
