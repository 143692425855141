import { GetterTree } from 'vuex';
import authenticationStateType from './authenticationStateType';

export const getters: GetterTree<authenticationStateType, any> = {
  accountStepsCompleted: (state) => state.areAccountCreationStepsCompleted,
  hasAnAppBeenCreated: (state) => state.hasAnAppBeenCreated,
  isPartnerAuthenticated: (state) => state.isPartnerAuthenticated,
  isAccountOwner: (state) => state.isAccountOwner,
  xPartnerToken: (state) => state.xPartnerToken,
  tokenExpirationDate: (state) => state.tokenExpirationDate,
  name: (state) => state.name,
  email: (state) => state.email,
  role: (state) => state.role,
  apps_types: (state) => state.apps_types,
  intercom_hash: (state) => state.intercom_user_hash,
};
