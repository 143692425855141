import store from '../../../store/index';
import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../../routes.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import {
  isPartnerAuthenticatedLocally,
  resetStorageAndGlobalStateAuth,
  resetStorageAndGlobalStatePermissions,
} from '@/api/useAuthStatus';
import { PartnerRoleEnum } from '@/types/models/partner.type';

const { useGetters } = createNamespacedHelpers(store, 'authentication');

export const useAuthentication = async (to: Route, from: Route, next: any) => {
  const { isPartnerAuthenticated, role } = useGetters(['isPartnerAuthenticated', 'role', 'isAccountOwner']);

  // log out
  if (to.name === RoutingRouteEnum.Logout && to.params.logout === 'logout') {
    await resetStorageAndGlobalStateAuth();
    await resetStorageAndGlobalStatePermissions();
    return next({ name: RoutingRouteEnum.LogIn });
  }

  //from any route to route that requires authentication
  if (to.matched.some((route) => route.meta.requireAuth)) {
    if (isPartnerAuthenticatedLocally() || isPartnerAuthenticated.value) next();
    else next({ name: RoutingRouteEnum.LogIn });
  }

  //from any route to route that doesn not require authentication
  if (!to.matched.some((route) => route.meta.requireAuth)) {
    if (isPartnerAuthenticatedLocally()) {
      switch (role.value) {
        case null:
          next({ name: RoutingRouteEnum.Home });
          return;
        case PartnerRoleEnum.PartnershipAdmin:
          next({ name: RoutingRouteEnum.ManagePartnerships_Apps });
          return;
        case PartnerRoleEnum.Admin:
          next({ name: RoutingRouteEnum.Admin_AppsManagement });
          return;
        case PartnerRoleEnum.Finance:
          next({ name: RoutingRouteEnum.FinanceAdmin_AppsSubscriptions });
          return;
        default:
          break;
      }
    } else next();
  }
};
