import store from '../store/index';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import dayjs from 'dayjs';

const { useGetters } = createNamespacedHelpers(store, 'authentication');

const useTokenStatus = () => {
  const { xPartnerToken, tokenExpirationDate } = useGetters(['xPartnerToken', 'tokenExpirationDate']);

  const partnerTokenValid = isTokenDateValid(tokenExpirationDate.value);

  const partnerToken = xPartnerToken.value;

  return {
    partnerToken,
    partnerTokenValid,
  };
};

export const isTokenDateValid = (tokenExpirationDate: string) => {
  return dayjs().isBefore(dayjs(tokenExpirationDate));
};

export default useTokenStatus;
