import { ActionTree } from 'vuex';
import { ApplicationType } from './applicationState.type';

export const actions: ActionTree<ApplicationType, unknown> = {
  setLoadingAppData({ commit }, isLoadingAppData) {
    commit('SET_IS_LOADING_APP_DATA', isLoadingAppData);
  },
  addStepCompleted({ commit }, stepCompleted) {
    commit('ADD_CREATE_APP_STEP_COMPLETED', stepCompleted);
  },
  removeStepsCompleted({ commit }, stepsRemoved) {
    commit('REMOVE_CREATE_APP_COMPLETED_STEPS', stepsRemoved);
  },
  setInProgressFullApplication({ commit }, fullApplication) {
    commit('SET_CURRENT_IN_PROGRESS_FULL_APP', fullApplication);
  },
  resetCreateAppProcess({ commit }) {
    commit('RESET_CREATE_APP_PROCESS_DATA');
  },
  // shipping application general data
  setShippingAppGeneralData({ commit }, payload) {
    commit('SET_SHIPPING_APP_GENERAL_DATA', payload);
  },
  // shipping application general data
};
