import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

export const emptyCreateAppState = {
  stepsCompletedList: [],
  inProgressFullApp: null, // already created, completing steps
  shippingAppGeneralData: null,
};

export default {
  namespaced: true,
  state: {
    loadingAppData: false,
    createApp: { ...emptyCreateAppState },
  },
  mutations,
  actions,
  getters,
};
