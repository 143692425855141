import { ActionTree } from 'vuex';
import RootStateType from './RootStateType.type';

const actions: ActionTree<RootStateType, unknown> = {
  setIsAppLoading({ commit }, payload) {
    commit('IS_APP_LOADING', payload);
  },
  setPartnerID({ commit }, payload) {
    commit('PARTNER_ID', payload);
  },
};

export default actions;
