import store from '@/store';
import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../../routes.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { PartnerRoleEnum } from '@/types/models/partner.type';

const { useGetters } = createNamespacedHelpers(store, 'authentication');

export const useRedirectToAdmin = async (to: Route, from: Route, next: any) => {
  const { role } = await useGetters(['role']);
  if (to.matched.some((route) => route.meta.isAdmin)) {
    switch (role.value) {
      case null:
        next({ name: RoutingRouteEnum.Home });
        return;
      case PartnerRoleEnum.PartnershipAdmin: {
        next({ name: RoutingRouteEnum.ManagePartnerships_Apps });
        return;
      }
      case PartnerRoleEnum.Admin:
        if (!to.matched.some((route) => route.meta.isAdmin)) next({ name: RoutingRouteEnum.Admin_AppsManagement });
        else next();
        return;
      default:
        break;
    }
  } else if (!to.matched.some((route) => route.meta.isAdmin)) {
    if (role.value === PartnerRoleEnum.Admin) {
      const toRoueName = to.name;
      if ([`${RoutingRouteEnum.DevelopmentStore}`, `${RoutingRouteEnum.Notifications}`].includes(toRoueName || '')) {
        next();
      } else {
        next({ name: RoutingRouteEnum.Admin_AppsManagement });
      }

      return;
    } else next();
  } else next();
};
