export const setLocalStorge = (key: string, value: any) => {
  return localStorage.setItem(key, value);
};

export const getLocalStorge = (key: string) => {
  return localStorage.getItem(key);
};

export const removeLocalStorge = (key: string) => {
  return localStorage.removeItem(key);
};
