import BaseModel from './BaseModel';
import ShippingApplicationModel from './ShippingApplicationModel';

class FullShippingApplicationInProgressResponseModel extends BaseModel {
  public payload: {
    app: ShippingApplicationModel;
    isAppInDevelopmentStore: boolean;
    development: string | null;
    hasCompletedPartnershipForm: boolean;
    mdLink: string;
  } | null;

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default FullShippingApplicationInProgressResponseModel;
