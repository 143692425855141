import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

export default {
  namespaced: true,
  state: {
    appID: -1,
    isEditMode: false,
    appType: '',
    stepsCompletedList: [],
  },
  mutations,
  actions,
  getters,
};
