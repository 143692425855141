import BaseModel from './BaseModel';

class ShippingApplicationModel extends BaseModel {
  public step: number;
  public app_id: number;
  public name_en: string;
  public name_ar: string;
  public partner_id: number;
  public developer_name_en: string;
  public developer_name_ar: string;
  public short_description_en: string;
  public short_description_ar: string;
  public url: string;
  public email: string;
  public mobile: string;
  public icon: string;
  public cover_photo: string | null;
  public description_en: string | null;
  public description_ar: string | null;
  public services: string | null;
  public type: number;
  public shipping_type: number;
  public payment_due_en: string | null;
  public payment_due_ar: string | null;
  public coverage: ShippingAppCoverageLocationsType | null;
  public times_to_pickup_en: string | null;
  public times_to_pickup_ar: string | null;
  public times_to_deliver_en: string | null;
  public times_to_deliver_ar: string | null;
  public weight_details_en: string | null;
  public weight_details_ar: string | null;
  public screen_shots_en: string;
  public screen_shots_ar: string;
  public pricing_model: string | null;
  public contract_link: string | null;
  public redirect_url: string | null;
  public callback_url: string | null;
  public listing_status: ShippingAppListingStatusProperty | null;
  public oauth_keys: Record<string, string> | null;

  constructor(arg: any) {
    super(arg);
    this.step = arg.step;
    this.app_id = arg.app_id;
    this.name_en = arg.name_en;
    this.name_ar = arg.name_ar;
    this.partner_id = arg.partner_id;
    this.developer_name_en = arg.developer_name_en;
    this.developer_name_ar = arg.developer_name_ar;
    this.short_description_en = arg.short_description_en;
    this.short_description_ar = arg.short_description_ar;
    this.url = arg.url;
    this.email = arg.email;
    this.mobile = arg.mobile;
    this.icon = arg.icon;
    this.cover_photo = arg.cover_photo;
    this.description_en = arg.description_en;
    this.description_ar = arg.description_ar;
    this.services = arg.services;
    this.type = arg.type;
    this.shipping_type = arg.shipping_type;
    this.payment_due_en = arg.payment_due_en;
    this.payment_due_ar = arg.payment_due_ar;
    this.coverage = arg.coverage;
    this.times_to_pickup_en = arg.times_to_pickup_en;
    this.times_to_pickup_ar = arg.times_to_pickup_ar;
    this.times_to_deliver_en = arg.times_to_deliver_en;
    this.times_to_deliver_ar = arg.times_to_deliver_ar;
    this.weight_details_en = arg.weight_details_en;
    this.weight_details_ar = arg.weight_details_ar;
    this.screen_shots_en = arg.screen_shots_en;
    this.screen_shots_ar = arg.screen_shots_ar;
    this.pricing_model = arg.pricing_model;
    this.contract_link = arg.contract_link;
    this.listing_status = arg.listing_status;
    this.redirect_url = arg.redirect_url;
    this.callback_url = arg.callback_url;
    this.oauth_keys = arg.oauth_keys;
  }
}

export enum ShippingAppListingStatusProperty {
  RequestToPublish = 1,
  Rejected = 2,
  Published = 3,
  Unpublished = 4,
}

export type ShippingAppCoverageLocationsType = {
  pickup_countries: Record<number, { label: string; value: number }[]>;
  delivery_countries: Record<number, { label: string; value: number }[]>;
  pickup_cities: Record<number, { label: string; value: number; country_id: number }[]>;
  delivery_cities: Record<number, { label: string; value: number; country_id: number }[]>;
};

export default ShippingApplicationModel;
