export enum AuthenticationResponse {
  PartnerID = 'partnerID',
  PartnerToken = 'x-partner-token',
  Name = 'name',
  Email = 'email',
  Role = 'role',
  Token_expiry_date = 'token_expiry_date',
  AppsTypes = 'apps_types',
  IsAccountOwner = 'is_account_owner',
  IntercomHash = 'intercom_hash',
}

export type RegisterStateType = {
  fullName: string;
  emailAddress: string;
  mobileNumber: string;
  password: string;
  passwordConfirm: string;
  country: {
    value: string;
  };
};

export enum InputFieldsEnum {
  EmailAddress = 'emailAddress',
  MobileNumber = 'mobileNumber',
  Password = 'password',
  PasswordConfirm = 'passwordConfirm',
  FullName = 'fullName',
}

export enum PatnerTypeEnum {
  Independent = 'independent',
  Employed = 'employed',
}

export enum PatnerTypeValuesEnum {
  Independent = 1,
  Employed = 0,
}

export enum AppTypeEnum {
  ZAM = 'zid-app-market',
  EnterpriseMerchant = 'enterprise-merchant',
  ThemeDeveloper = 'theme-developer',
}

export enum AppTypeValuesEnum {
  ZAM = 1,
  enterpriseMerchant = 0,
  ThemeDeveloper = 2,
}
