<template>
  <router-link
    :to="toRoute"
    :class="{
      'side-link block ps-5 pe-2 py-4 focus:text-gray-900 focus:outline-none focus:shadow-outline': true,
      'hover:bg-primary-600': !isDropdownItem,
      'rounded-xl hover:bg-primary': isDropdownItem,
    }"
  >
    <div class="flex items-center">
      <slot name="icon"> </slot>
      <slot name="menuItem"> </slot>
    </div>
  </router-link>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: ['toRoute', 'isDropdownItem'],
});
</script>
