<template>
  <div
    class="notifications-dropdown w-96 px-4 py-6 relative bg-white-pureWhite shadow-md rounded-md flex flex-col items-start z-40"
  >
    <font-awesome-icon class="absolute left-1/2 -top-2" color="white" icon="caret-up" />
    <div class="dropdown-container w-full max-h-96 overflow-y-auto px-2">
      <div v-if="(notificationsList.data || []).length" class="w-full flex justify-end">
        <router-link to="/notifications" class="mb-3">
          <base-text textStyle="h4" classes="text-neutrals-black"> See all </base-text>
        </router-link>
      </div>
      <div v-else class="w-full flex justify-center">
        <base-text textStyle="p3" classes="text-primary"> There are no notifications to show </base-text>
      </div>
      <notification-item
        v-for="notification in notificationsList.data"
        :key="notification.id"
        :notificationType="notification.listing_status"
        :partnerName="name"
        :notificationBody="notification.body"
        :notificationDateTimeCreated="notification.created_at"
        classes="mb-2"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onBeforeMount, watch } from '@vue/composition-api';
import NotificationItem from '../notification-item/NotificationItem.vue';
import NotificationType from '../../types/components/Notification.type';
import { getNotificationsList } from '@/api/top-level-apis/notifications/notifications';
import BaseText from '@/components/text/BaseText.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { NotificationTypeEnum } from '@/types/components/Notification.type';
import ErrorModel from '@/api/models/ErrorModel';

const { useGetters } = createNamespacedHelpers('authentication');

export default defineComponent({
  emits: ['updateNewNotificationMark'],
  components: {
    NotificationItem,
    BaseText,
  },
  setup(_, { emit }) {
    const notificationsList = reactive<Record<string, NotificationType[]>>({
      data: [],
    });

    const { name } = useGetters(['name']);

    onBeforeMount(async () => {
      const response = await getNotificationsList();
      if (response instanceof ErrorModel || !response.notes) return;
      notificationsList.data = response.notes;
    });

    watch(
      () => notificationsList,
      (notificationsList) => {
        if (notificationsList.data) {
          const hasUnseenNotifications = notificationsList.data.some((notification) => notification.is_seen === 0);
          emit('updateNewNotificationMark', hasUnseenNotifications);
        }
      },
      { deep: true },
    );

    return {
      name,
      notificationsList,
      NotificationTypeEnum,
    };
  },
});
</script>

<style lang="scss" scoped>
.notifications-dropdown {
  .dropdown-container {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      background: theme('colors.gray.DEFAULT');
    }
    &::-webkit-scrollbar-thumb {
      background: theme('colors.green.DEFAULT');
      border-radius: 8px;
    }
  }
}
</style>
