import store from '@/store';
import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../../routes.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { PartnerRoleEnum } from '@/types/models/partner.type';

const { useGetters } = createNamespacedHelpers(store, 'authentication');

export const useRedirectToFinanceAdmin = async (to: Route, from: Route, next: any) => {
  const { role } = await useGetters(['role']);

  if (to.matched.some((route) => route.meta.isFinanceAdmin)) {
    if (role.value !== PartnerRoleEnum.Finance) {
      next({ name: RoutingRouteEnum.Home });
      return;
    }
    next();
  } else {
    if (role.value === PartnerRoleEnum.Finance) {
      next({ name: RoutingRouteEnum.FinanceAdmin_AppsSubscriptions });
      return;
    }
    next();
  }
};
