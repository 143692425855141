import store from '@/store';
import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../../routes.enum';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { PartnerRoleEnum } from '@/types/models/partner.type';
import i18nFactory from '@/helpers/i18n/i18nFactory';
import I18nLocaleEnum from '@/helpers/i18n/i18nLocale.enum';

const { useGetters } = createNamespacedHelpers(store, 'authentication');

export const useRedirectToPartnershipAdmin = async (to: Route, from: Route, next: any) => {
  const { role } = await useGetters(['role']);

  if (to.matched.some((route) => route.meta.requireAuth)) {
    switch (role.value) {
      case null:
        if (to.matched.some((route) => route.meta.isPartnershipAdmin)) next({ name: RoutingRouteEnum.Home });
        else next();
        return;
      case PartnerRoleEnum.PartnershipAdmin: {
        const i18n = i18nFactory();
        i18n.locale = I18nLocaleEnum.en;
        if (to.matched.some((route) => route.meta.isPartnershipAdmin)) {
          if (from.name === null && to.name === RoutingRouteEnum.ManagePartnerships_Apps) {
            store.dispatch('setIsAppLoading', true);
            next();
          } else if (from.name === null && to.name === RoutingRouteEnum.ManagePartnerships_Partner) {
            store.dispatch('setIsAppLoading', true);
            next({ name: RoutingRouteEnum.ManagePartnerships_Apps });
          } else if (
            from.name === RoutingRouteEnum.ManagePartnerships_Partner &&
            to.name === RoutingRouteEnum.ManagePartnerships_Apps
          ) {
            store.dispatch('setIsAppLoading', true);
            next();
          } else next();
        } else next({ name: RoutingRouteEnum.ManagePartnerships_Apps });
        return;
      }
      case PartnerRoleEnum.Admin:
        if (to.matched.some((route) => route.meta.isPartnershipAdmin))
          next({ name: RoutingRouteEnum.Admin_AppsManagement });
        else next();
        return;
      default:
        break;
    }
  } else next();
};
