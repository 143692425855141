import { ActionTree } from 'vuex';
import authenticationStateType from './authenticationStateType';

export const actions: ActionTree<authenticationStateType, unknown> = {
  resetPartnerData({ commit }) {
    commit('RESET_PARTNER_DATA');
  },
  setAccountCreationStepsState({ commit }, accountCompletionUpdate) {
    commit('SET_ACCOUNT_CREATION_STEPS_STATE', accountCompletionUpdate);
  },
  setAnAppHasBeenCreated({ commit }, hasAnAppBeenCreated) {
    commit('SET_AN_APP_HAS_BEEN_CREATED', hasAnAppBeenCreated);
  },
  setPartnerAuthenticated({ commit }, isPartnerAuthenticated) {
    commit('SET_PARTNER_AUTHENTICATED', isPartnerAuthenticated);
  },
  setIsAccountOwner({ commit }, isAccountOwner) {
    commit('SET_IS_ACCOUNT_OWNER', isAccountOwner);
  },
  setXPartnerTokenState({ commit }, xPartnerToken) {
    commit('SET_X_PARTNER_TOKEN', xPartnerToken);
  },
  setTokenExpirationDate({ commit }, tokenExpirationDate) {
    commit('SET_TOKEN_EXPIRATION_DATE', tokenExpirationDate);
  },
  setPartnerName({ commit }, partnerName) {
    commit('SET_PARTNER_NAME', partnerName);
  },
  setPartnerEmail({ commit }, partnerEmail) {
    commit('SET_PARTNER_EMAIL', partnerEmail);
  },
  setPartnerRole({ commit }, partnerRole) {
    commit('SET_PARTNER_ROLE', partnerRole);
  },
  setPartnerAppsTypes({ commit }, apps_types) {
    commit('SET_PARTNER_APPS_TYPES', apps_types);
  },
  setPartnerCreatedAppType({ commit }, app_type) {
    commit('SET_PARTNER_CREATED_APP_TYPE', app_type);
  },
  setPartnerIntercomHash({ commit }, hash) {
    commit('SET_PARTNER_INTERCOM_HASH', hash);
  },
};
