import { FontWeightsType } from '../../../types/components/text/textTypes';

export const FONT_WEIGHTS: FontWeightsType = {
  h1: {
    sm: 500,
    md: 600,
    lg: 600,
  },
  h2: {
    sm: 500,
    md: 600,
    lg: 600,
  },
  h3: {
    sm: 500,
    md: 600,
    lg: 600,
  },
  h4: {
    sm: 400,
    md: 500,
    lg: 500,
  },
  h5: {
    sm: 300,
    md: 400,
    lg: 400,
  },
  p1: {
    sm: 500,
    md: 500,
    lg: 500,
  },
  p2: {
    sm: 500,
    md: 500,
    lg: 500,
  },
  p3: {
    sm: 400,
    md: 400,
    lg: 400,
  },
  p4: {
    sm: 400,
    md: 400,
    lg: 400,
  },
  p5: {
    sm: 400,
    md: 400,
    lg: 400,
  },
};
