import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

export default {
  namespaced: true,
  state: {
    areAccountCreationStepsCompleted: false,
    hasAnAppBeenCreated: false,
    isPartnerAuthenticated: false,
    isAccountOwner: false,
    xPartnerToken: '',
    tokenExpirationDate: '',
    name: '',
    email: '',
    role: 0,
    apps_types: {
      public_apps: false,
      shipping_apps: false,
    },
    intercom_user_hash: '',
  },
  mutations,
  actions,
  getters,
};
