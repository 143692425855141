import ErrorModel from '@/api/models/ErrorModel';
import httpClient from '../../httpClient';
import FullShippingApplicationInProgressResponseModel from '../../models/FullShippingApplicationInProgressResponseModel';

const ENDPOINT_URL = '/market/shipping';

export const getShippingApplicationData = (
  applicationID: number,
): Promise<FullShippingApplicationInProgressResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/${applicationID}`)
    .then((response) => new FullShippingApplicationInProgressResponseModel(response))
    .catch((error) => error);
};
