import BaseModel from './BaseModel';
import NotificationType from '../../types/components/Notification.type';

class NotificationsResponseModel extends BaseModel {
  public notes: NotificationType[];

  constructor(arg: any) {
    super(arg);
    this.notes = arg.notes;
  }
}

export default NotificationsResponseModel;
