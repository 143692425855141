import Vue from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import router from '@/router';
import AppHeader from '../header/AppHeader.vue';
import BaseText from '../text/BaseText.vue';
import SidebarItem from './SidebarItem.vue';
import useIcons from './sidebar-icons/useIcons';
import FinanceSidebarMenu from './domains/finance-admin/FinanceSidebarMenu.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { API_DOCUMENTATION, METABASE_APPS_ANALYTICS, METABASE_STORE_LIST } from '@/helpers/constantLinks';
import { logoutPartner } from '@/domains/authentication/api/logout';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { PartnerRoleEnum } from '@/types/models/partner.type';
import { ZidIcon } from '@zidsa/ui';

const { useGetters } = createNamespacedHelpers('authentication');

const ICONS = useIcons();

export default defineComponent({
  props: ['menuOpen'],
  components: {
    AppHeader,
    BaseText,
    SidebarItem,
    ...ICONS,
    ZidIcon,
    FinanceSidebarMenu,
  },
  setup(_, ctx) {
    const { accountStepsCompleted, role, apps_types } = useGetters(['accountStepsCompleted', 'role', 'apps_types']);
    const APIsDocumentationLink = ref(API_DOCUMENTATION);
    const AdminAppsAnalyticsLink = ref(METABASE_APPS_ANALYTICS);
    const AdminStoreListLink = ref(METABASE_STORE_LIST);
    // partnership admin
    const showShippingAppsContent = ref(false);
    const showPublicAppsContent = ref(false);
    // Admin
    const showAdminAdmininistration = ref(
      ctx.root.$route.name === RoutingRouteEnum.LogIn || ctx.root.$route.name === RoutingRouteEnum.Admin_AppsManagement,
    );
    const showAdminPublicAndPrivateApps = ref(false);
    const showAdminZamWebsite = ref(false);
    const showThemesContent = ref(false);
    const showPaymentsContent = ref(false);

    const toggleShowPublicAppsContent = () => (showPublicAppsContent.value = !showPublicAppsContent.value);
    const toggleShowShippingAppsContent = () => (showShippingAppsContent.value = !showShippingAppsContent.value);
    const toggleShowAdminPublicAndPrivateApps = () =>
      (showAdminPublicAndPrivateApps.value = !showAdminPublicAndPrivateApps.value);
    const toggleShowAdmininistration = () => (showAdminAdmininistration.value = !showAdminAdmininistration.value);
    const toggleShowAdminZamWebsite = () => (showAdminZamWebsite.value = !showAdminZamWebsite.value);
    const toggleShowThemesContent = () => (showThemesContent.value = !showThemesContent.value);
    const toggleShowPaymentsContent = () => (showPaymentsContent.value = !showPaymentsContent.value);

    const onLogoutClicked = async () => {
      const response = await logoutPartner();
      if (response.status === 'success') {
        router.push({ name: RoutingRouteEnum.Logout, params: { logout: 'logout' } }).catch((err) => {
          /**/
        });
        window.Intercom('shutdown');
        Vue.$toast.success('', { message: 'Logged out successfully', position: 'bottom' });
      } else {
        Vue.$toast.error('', { message: 'Error logging out', position: 'top' });
      }
    };

    return {
      accountStepsCompleted,
      APIsDocumentationLink,
      AdminAppsAnalyticsLink,
      AdminStoreListLink,
      showAdminAdmininistration,
      showAdminPublicAndPrivateApps,
      showAdminZamWebsite,
      showThemesContent,
      showPaymentsContent,
      role,
      apps_types,
      PartnerRoleEnum,
      showShippingAppsContent,
      showPublicAppsContent,
      toggleShowPublicAppsContent,
      toggleShowShippingAppsContent,
      toggleShowAdminPublicAndPrivateApps,
      toggleShowAdmininistration,
      toggleShowAdminZamWebsite,
      toggleShowThemesContent,
      toggleShowPaymentsContent,
      onLogoutClicked,
    };
  },
});
