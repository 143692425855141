import { setLocalStorge, getLocalStorge } from '../localStorage';
import { LocaleStorage } from '@/types/i18n/i18n.enum';
import I18nLocaleEnum from './i18nLocale.enum';

const getSelectedLocaleFromLocalStorage = () => {
  const storedLocale = getLocalStorge(LocaleStorage.PartnerDashboardLocale);

  if (!storedLocale || !(storedLocale in I18nLocaleEnum)) {
    setLocalStorge(LocaleStorage.PartnerDashboardLocale, I18nLocaleEnum.en);
    return I18nLocaleEnum.en;
  }

  switch (storedLocale) {
    case I18nLocaleEnum.ar:
      return I18nLocaleEnum.ar;
    case I18nLocaleEnum.en:
      return I18nLocaleEnum.en;
    default:
      return I18nLocaleEnum.en;
  }
};

export default getSelectedLocaleFromLocalStorage;
