import { PagesNamesEnum } from '@/router/pages.names.enum';
import { RootPermissionsEnum } from '@/router/permissions/root-permissions.enum';
import { RoutingRouteEnum } from '@/router/routes.enum';

export const getRouteKeyFromRouteName = (name: RoutingRouteEnum): string => {
  return Object.keys(RoutingRouteEnum)[Object.values(RoutingRouteEnum).lastIndexOf(name)];
};

export const getPermissionKeyFromPermission = (permission: RootPermissionsEnum): string => {
  return Object.keys(RootPermissionsEnum)[Object.values(RootPermissionsEnum).lastIndexOf(permission)];
};

export const getRouteNameFromRouteKey = (routeKey: PagesNamesEnum): string => {
  return Object.values(PagesNamesEnum)[Object.keys(PagesNamesEnum).lastIndexOf(routeKey)];
};
