import { MutationTree } from 'vuex';
import authenticationStateType from './authenticationStateType';
import { AppTypesKeysEnum } from '@/types/global-types/appTypes.enum';

export const mutations: MutationTree<authenticationStateType> = {
  RESET_PARTNER_DATA(state) {
    state.areAccountCreationStepsCompleted = false;
    state.hasAnAppBeenCreated = false;
    state.isPartnerAuthenticated = false;
    state.isAccountOwner = false;
    state.xPartnerToken = '';
    state.tokenExpirationDate = '';
    state.name = '';
    state.email = '';
    state.role = 0;
    state.apps_types = {
      public_apps: false,
      shipping_apps: false,
    };
    state.intercom_user_hash = '';
  },
  SET_ACCOUNT_CREATION_STEPS_STATE(state, payload) {
    state.areAccountCreationStepsCompleted = payload;
  },
  SET_AN_APP_HAS_BEEN_CREATED(state, payload) {
    state.hasAnAppBeenCreated = payload;
  },
  SET_PARTNER_AUTHENTICATED(state, payload) {
    state.isPartnerAuthenticated = payload;
  },
  SET_IS_ACCOUNT_OWNER(state, payload) {
    state.isAccountOwner = payload;
  },
  SET_X_PARTNER_TOKEN(state, payload) {
    state.xPartnerToken = payload;
  },
  SET_TOKEN_EXPIRATION_DATE(state, payload) {
    state.tokenExpirationDate = payload;
  },
  SET_PARTNER_NAME(state, payload) {
    state.name = payload;
  },
  SET_PARTNER_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_PARTNER_ROLE(state, payload) {
    state.role = payload;
  },
  SET_PARTNER_APPS_TYPES(state, payload) {
    state.apps_types = payload;
  },
  SET_PARTNER_CREATED_APP_TYPE(state, payload: AppTypesKeysEnum) {
    state.apps_types[payload] = true;
  },
  SET_PARTNER_INTERCOM_HASH(state, payload) {
    state.intercom_user_hash = payload;
  },
};
