import { RouteConfig } from 'vue-router';
import { RoutingRouteEnum } from '../routes.enum';

export const FINANCE_ADMIN_ROUTES: Array<RouteConfig> = [
  {
    path: `/${RoutingRouteEnum.FinanceAdmin_AppsSubscriptions}`,
    name: RoutingRouteEnum.FinanceAdmin_AppsSubscriptions,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isFinanceAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "financeAdminAppsSubscriptions" */ '@/domains/finance-admin/views/apps-subscriptions/AppsSubscriptions.vue'
      ),
  },
  {
    path: `/${RoutingRouteEnum.FinanceAdmin_PartnersManagement}`,
    name: RoutingRouteEnum.FinanceAdmin_PartnersManagement,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isFinanceAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "financeAdminPartnersManagement" */ '@/domains/finance-admin/views/partners-management/PartnersManagement.vue'
      ),
  },
  {
    path: `/${RoutingRouteEnum.FinanceAdmin_PartnerDetails}/:id`,
    name: RoutingRouteEnum.FinanceAdmin_PartnerDetails,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isFinanceAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "financeAdminPartnersManagement" */ '@/domains/finance-admin/views/partners-management/partner-details/PartnerDetails.vue'
      ),
  },
  {
    path: `/${RoutingRouteEnum.FinanceAdmin_ThemesManagement}`,
    name: RoutingRouteEnum.FinanceAdmin_ThemesManagement,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isFinanceAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "financeAdminPartnersManagement" */ '@/domains/finance-admin/views/themes-management/ThemesManagement.vue'
      ),
  },
  {
    path: `/${RoutingRouteEnum.FinanceAdmin_ShippingOrders}`,
    name: RoutingRouteEnum.FinanceAdmin_ShippingOrders,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isFinanceAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "financeAdminShipping" */ '@/domains/finance-admin/views/shipping/shipping-orders/ShippingOrders.vue'
      ),
  },
  {
    path: `/${RoutingRouteEnum.FinanceAdmin_ShippingActivations}`,
    name: RoutingRouteEnum.FinanceAdmin_ShippingActivations,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isFinanceAdmin: true },
    component: () =>
      import(
        /* webpackChunkName: "financeAdminShipping" */ '@/domains/finance-admin/views/shipping/shipping-activations/ShippingActivations.vue'
      ),
  },
  {
    path: `/${RoutingRouteEnum.FinanceAdmin_Payouts}`,
    name: RoutingRouteEnum.FinanceAdmin_Payouts,
    meta: { requireAuth: true, requireAccountStepsCompletion: false, isFinanceAdmin: true },
    component: () =>
      import(/* webpackChunkName: "financeAdminPayouts" */ '@/domains/finance-admin/views/payouts/Payouts.vue'),
  },
];
