import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEye,
  faEyeSlash,
  faCaretUp,
  faChevronUp,
  faChevronDown,
  faCheck,
  faExclamation,
  faGripLines,
  faLink,
  faRocket,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faEye,
  faEyeSlash,
  faCaretUp,
  faChevronUp,
  faChevronDown,
  faCheck,
  faExclamation,
  faGripLines,
  faLink,
  faRocket,
);
