<template>
  <div class="shadow mt-3 ms-2.5 p-7 relative bg-white-pureWhite rounded-xl flex flex-col items-start z-20">
    <font-awesome-icon class="absolute left-1/2 -top-2" color="white" icon="caret-up" />
    <div v-if="includeProfileAndNotificationsLinks" class="space-y-5 mb-5">
      <div v-if="accountStepsCompleted">
        <router-link :to="{ name: RoutingRouteEnum.Notifications }">
          <div class="flex items-center hover:text-primary-600">
            <base-icon viewBox="0 0 18 17" class="me-1 w-5" iconName="notification">
              <notifications-icon />
            </base-icon>
            <base-text textStyle="p3"> Notifications </base-text>
          </div>
        </router-link>
      </div>
      <div v-if="accountStepsCompleted">
        <router-link :to="{ name: RoutingRouteEnum.Profile }">
          <div class="flex items-center hover:text-primary-600">
            <base-icon viewBox="0 0 18 17" class="me-2 w-4" iconName="profile">
              <profile-icon />
            </base-icon>
            <base-text textStyle="p3"> My Profile </base-text>
          </div>
        </router-link>
      </div>
    </div>
    <div>
      <div class="cursor-pointer" @click="onLogout">
        <div class="flex items-center hover:text-red">
          <base-icon viewBox="0 0 14 14" class="me-2 w-4" iconName="logout">
            <logout-icon />
          </base-icon>
          <base-text textStyle="p3"> Log out </base-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BaseText from '../text/BaseText.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import router from '@/router';
import { logoutPartner } from '../../domains/authentication/api/logout';
import { PartnerRoleEnum } from '@/types/models/partner.type';
import useIcons from '../sidebar/sidebar-icons/useIcons';
const ICONS = useIcons();
const { useGetters } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    BaseText,
    ...ICONS,
  },
  setup() {
    const rolesWithoutNotifications = [PartnerRoleEnum.PartnershipAdmin, PartnerRoleEnum.Finance];
    const { accountStepsCompleted, role } = useGetters(['accountStepsCompleted', 'role']);
    const includeProfileAndNotificationsLinks = ref(rolesWithoutNotifications.indexOf(role.value) === -1);
    const onLogout = async () => {
      const response = await logoutPartner();
      if (response.status === 'success') {
        router.push({ name: RoutingRouteEnum.Logout, params: { logout: 'logout' } }).catch((err) => {
          /**/
        });
        window.Intercom('shutdown');
        Vue.$toast.success('', { message: 'Logged out successfully', position: 'bottom' });
      } else {
        Vue.$toast.error('', { message: 'Error logging out', position: 'top' });
      }
    };
    return {
      RoutingRouteEnum,
      accountStepsCompleted,
      role,
      includeProfileAndNotificationsLinks,
      PartnerRoleEnum,
      onLogout,
    };
  },
});
</script>
