import { ActionTree } from 'vuex';
import ApplicationsType from './applicationsStateType.type';

export const actions: ActionTree<ApplicationsType, unknown> = {
  setSearchedApplicationName(utils, applicationName) {
    if (applicationName.length) {
      const appsList = utils.state.applicationsList;
      const newFilteredList = appsList.filter((application) =>
        application?.name?.toLowerCase().includes(applicationName?.toLowerCase()),
      );
      utils.commit('SET_FILTERED_APPLICATIONS_LIST', newFilteredList);
    } else {
      utils.commit('SET_FILTERED_APPLICATIONS_LIST', utils.state.applicationsList);
    }
    utils.commit('SET_SEARCHED_APPLICATION_NAME', applicationName);
  },
  setApplicationsList({ commit }, appsList) {
    commit('SET_APPLICATIONS_LIST', appsList);
  },
  setFilteredApplicationsList({ commit }, filteredAppsList) {
    commit('SET_FILTERED_APPLICATIONS_LIST', filteredAppsList);
  },
};
